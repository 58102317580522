import React from 'react';
import './Ingredient.css';
import { useSelector } from 'react-redux'
import {EditCooking } from '../index.js'

export function Ingredient(props) {
  const item = props.item
  const {ingredients} = useSelector(state => state.ingredients)
  const {lib, statusLib} = useSelector(state => state.lib)
  const ingredient = ingredients.find(f => f._id === item._id);
  const choisMeasure = statusLib === 'resolved' && lib.measure.find(f => f._id === item.choisMeasure || f.nameRu === item.choisMeasure);
 
  function handleDelete() {
    props.onClick(item._id)
  }
   //console.log(item)
  return (
    <article className='add-recipe__type_conteiner'>
      <div className= 'ingredient__content'>
        <p className='ingredients__name font20Norm'>{ingredient.name} {ingredient.trait}</p> 
        <div className='add-recipe__label add-recipe__label_type_weight'>
        <input 
          autoComplete="off" 
          disabled={true} 
          type='number' 
          value={item.weight} 
          onChange={props.handleChange} 
          placeholder='Количество' 
          className='add-recipe__input add-recipe__input_type_ingredient-weight' 
          name='weight' 
          id='weight'
        />
        <p className='add-recipe__input_type_ingredient-measure'> {item.choisMeasure}</p> 
      </div>
        </div>
       
        <div className='ingredient__content'>
        <EditCooking  
        thermoself={item.thermoself} 
        onChange={props.onChange}
        item={item}
        editChange={props.editChange}
      />
        <p onClick={handleDelete} className='ingredient__delete' />
       
      </div>
    </article>
)
   
}
