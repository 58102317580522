
// Разметка меню 1 дня
import React, { memo } from 'react'; 
import { MenuMeals } from '../../molecules/index.js'
import { InfoPopup, AddMeals } from '../../atoms/index.js'
import './MenuItem.css'; 
import { useSelector} from 'react-redux' 

export const MenuItem = memo((props) => { 
  console.log(props.item)
  const item = props.item
  const {ingredients, status} = useSelector(state => state.ingredients)
  const {recipes} = useSelector(state => state.recipes)   
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes)
  const {statusPlan} = useSelector(state => state.plans)
  const [click, setClick] = React.useState(false) // Отслеживание клика для вывода прелоудера в меню
  const [isOpen, setIsOpen] = React.useState(false);
  const myRef = React.useRef()
  const { date, breakfast, lunch, supper, dinner, snack, lateDinner } = item
  const d = new Date(date);
  const n = d.getDay(); 
  const today = new Date();  
  const blockedSaveMenu = date < today.setHours(0,0,0,0)
  const days = [
    'Вс',
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб'
  ];
  const newarr = [
    {
      arr: breakfast,
      mealsRu: 'Завтрак',
      mealsEn: 'breakfast',
    }, 
    {
      arr: lunch,
      mealsRu: 'Ланч',
      mealsEn: 'lunch',
    }, 
    {
      arr: dinner,
      mealsRu: 'Обед',
      mealsEn: 'dinner',
    }, 
    {
      arr: snack,
      mealsRu: 'Полдник',
      mealsEn: 'snack',
    }, 
    {
      arr: supper,
      mealsRu: 'Ужин',
      mealsEn: 'supper',
    }, 
    {
      arr: lateDinner,
      mealsRu: 'Поздний ужин',
      mealsEn: 'lateDinner',
    }, 
  ]
  const zeroMeals = newarr.filter(i=> i.arr.length === 0) //Отсутствующие приемы пищи за день
  let [cal, setCal] = React.useState(0)
  let [price, setPrice] = React.useState(0) 
 
  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (isOpen && myRef.current && !myRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [isOpen])

  React.useEffect(()=> {
    if(statusPlan === 'resolved') {
      setClick(false)
    }
  }, [statusPlan])
 
  
  React.useEffect(() => { 
    if(status === 'resolved' && statusPlan ==='resolved' && statusMenu === 'resolved') { 
      let {cal, price} = countSumCalories()
      setCal(cal)
      setPrice(price)
    }
  }, [item, statusMenu]) 
  
  //Расчет итоговых калорий за день 
  function countSumCalories() {
    let cal= 0
    let price= 0
    const dex = {...item}  
    Object.values(dex).map((d) => { 
      if(Array.isArray(d) && d.length> 0) {  
        const fin = d.filter(i=>!i.weight)
        fin.map(item => {
          let showRecipe = recipeDataMenu.find(r => r._id === item)
          if(showRecipe !== undefined) {
            let result = showRecipe.ingredients.reduce(function(acc,v, index){
            const ingredient = ingredients.find(f => f._id === v._id);
            if(ingredient  !== undefined) {
              const totalWeights = ingredient.measure.find(i=> i.nameRu === v.choisMeasure)
              if(totalWeights) {
                const totalWeight = totalWeights.ratio*v.weight;
                acc.calories += Number(ingredient.calories.calories*totalWeight/100)
              if(ingredient.price) {
                console.log(item)
                if(item.choisMeasure === ingredient.mainMeasure && ingredient.price.measureprice === ingredient.mainMeasure) {
                  const price = (ingredient.price.rate/ingredient.price.weightprice)*item.weight
                  acc.price += Number(price)
                } else { 
                  //перевести цену за основной вес
                  const rightPrice = ingredient.measure.find(i=> i.nameRu === ingredient.price.measureprice).ratio
                  const price = (ingredient.price.rate/(ingredient.price.weightprice*rightPrice)) * totalWeight
                  acc.price += Number(price)
                } 
              }
            }
            }
              return acc;
          }, { calories:0, price: 0});
         
          cal += result.calories/showRecipe.servings
          price += result.price/showRecipe.servings
        }
         })
        }
      })
      
    return {cal, price} 
  }

                              /* Действия с планом на день*/ 
  //функция удаления меню с определенного дня
  function handleDeleteMenuDay() {
    if(!blockedSaveMenu) {
      props.handleDeleteMenuDay(item._id)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    }  
  }

  // Автозамена плана на день
  function handleAutoReplaceMenu() { 
    if(!blockedSaveMenu) {
      props.handleAutoReplaceMenu(item)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  } 
  
  // Добавления дополнительного приема пищи в план
  function handleOpenAddMeals() {
    if(!blockedSaveMenu) {
      setIsOpen(!isOpen)
    } else {
      props.blockedSaveMenus('blockedSaveMenu')
    }  
  }

  //Добавление приема пищи в план
  function handleAddMeals(meals) {
    props.handleAddMeals(meals, item) 
    setIsOpen(!isOpen)
  } 

                                 /* Действия с планом на каждый прием пищи*/ 
  //функция добавления блюда в план
  function handleAddMenuItem(mealsRu, mealsEn) {
    if(!blockedSaveMenu) {
      props.handleAddMenuItem(mealsRu, mealsEn, item)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }
  //функция удаления блюда из плана
    function handleDeleteRecipe(id, powerMode) {
      props.handleDeleteRecipe(id, powerMode, item)
    }
  // Автозамена блюда в плане, передает данные в модалку для замены рецепта
  function handleAutoReplace(recipe, mealsEn, mealsRu) { 
    props.handleAutoReplace(recipe, mealsEn, mealsRu, item)
  }
  
  //функция замены блюда из плана
  function handleEdite(id, mealsEn, mealsRu) {
    props.handleEdite(id, mealsEn, mealsRu, item)
  }
  
  return ( 
    <div className='menu-item'>
      <div className='menu-item__date font20Norm'>
        <p className='menu-item__weekday'>{item ? days[n] : ''}</p>
        <p className='menu-item__day'>{item ? d.toLocaleDateString() : ''}</p>
        <button className='gg-replaceDay x-size' onClick={handleAutoReplaceMenu}/>
        <button className='gg-add x-size' onClick={handleOpenAddMeals}></button>
        <button className='gg-delete-white x-size' onClick={handleDeleteMenuDay}/>
        <InfoPopup 
          isOpen={isOpen}
          ref={myRef}  
          type='meal'
        >
         {zeroMeals.map((item, index)=> (
        <AddMeals 
          item={item} 
          onClick={handleAddMeals} 
          key={item.mealsEn} />
      ))}
         
      </InfoPopup> 
      </div>
      {
        newarr.map((i, index)=> (
          <MenuMeals 
            item={i} 
            key= {index}
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe = {handleDeleteRecipe}
            handleEdite = {handleEdite}
            autoReplace={handleAutoReplace}
            handleAddDish={handleAddMenuItem}
            click={click} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus} 
          />
        ))
      }
      <div className='menu-item__bottom font16Norm'>
        <p className='menu-item__cal'>{Math.ceil(cal)} Ккал</p>
        <p className='menu-item__price'> {Math.ceil(price)} &#8381;</p>
      </div>
      
    
     </div>
  )  
})