class Api {
  constructor ({address, headers}) {
    this._address = address;
    this._headers = headers;
  }

  _getResponseData(res) {
    if(res.ok) {
      return res.json()
     }else {
       return Promise.reject(res.status)
     } 
  }

  //получение данных о рецепте
  getSitemap() {
    return fetch(`${this._address}/sitemap.xml`, {
      method: 'GET',
      headers: this._headers, 
    })
    .then(this._getResponseData)
  }

  getRecipeId (id) {
    return fetch(`${this._address}/recipe/${id}`, {
      method: 'GET',
      headers: this._headers,
      credentials: 'include',
    })
    .then(this._getResponseData)
  }

    //получение данных о рецепте
    getRecipeSuite () {
      return fetch(`${this._address}/recipe/suite`, {
        method: 'GET',
        headers: this._headers,
        credentials: 'include',
      })
      .then(this._getResponseData)
    }

  //Запрос на постановку или удаление лайка
  changeLikeCardStatus (id, isLiked) {
    return fetch(`${this._address}/recipe/${id}/likes`, {
      method: isLiked ? 'PUT' : 'DELETE',
      headers: this._headers,
      credentials: 'include'
    })
    .then(this._getResponseData)
  }

  getServerMenu(choiceDate) {  
    return fetch(`${this._address}/servergener`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      credentials: 'include',
      body: JSON.stringify({
        date: choiceDate,
      })
    })
    .then(this._getResponseData)
  } 

  getServerFeastMenu(title, counts, capacity, types) {  
    return fetch(`${this._address}/feastmenu`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      credentials: 'include',
      body: JSON.stringify({
        title: title,
        servings: capacity,
        types: types,
        counts: counts,
        
      })
    })
    .then(this._getResponseData)
  } 

  getFeastDish(meals, types, id) {   
    return fetch(`${this._address}/feastmenu/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      credentials: 'include',
      body: JSON.stringify({
        meals: meals,
        types: types,
        id: id,
        
      })
    })
    .then(this._getResponseData)
  } 


  getRecipePlan(category) {  
    return fetch(`${this._address}/recipe/params`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      credentials: 'include',
      body: JSON.stringify({
        category: category,
      })
    })
    .then(this._getResponseData)
  } 


  getRecipeData(id) {  
    return fetch(`${this._address}/recipe/suite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      credentials: 'include',
      body: JSON.stringify({
        id: id,
      })
    })
    .then(this._getResponseData)
  } 


// Редактирование ингредиентов
editIngredients(arr) { 
  return fetch(`${this._address}/ingredients/${arr.id}`, {
    method: 'PATCH',
    headers: this._headers,
    credentials: "include",
    body: JSON.stringify({
      name: arr.values.name,
      calories: {
        protein: arr.values.protein,
        fats: arr.values.fats,
        carb: arr.values.carb,
        calories: arr.values.calories,
        digest: arr.values.digest,
      },
      measure: arr.measure,
      accusativeCase: '',
      genitiveCase: '',
      instrumentalCase: '',
      category: arr.values.category,
      desc: arr.values.desc,
      image: [],
      choisMeasure: 'г',
      weight: 0,
      link: arr.values.author,
    })
  })
  .then(this._getResponseData)
}

 //Получение ингредиента по id
 getIngredientId (id) {
  return fetch(`${this._address}/ingredients/${id}`, {
    method: 'GET',
    headers: this._headers,
    credentials: 'include',
  })
  .then(this._getResponseData)
}
  //Получение всех зарегистрированных пользователей
  getUsers () {
    return fetch(`${this._address}/users`, {
      method: 'GET',
      headers: this._headers,
      credentials: 'include',
    })
    .then(this._getResponseData)
    }

  // Продление подписки через бонусы
  updateSubsUser(dateAcces, bonus) {
    return fetch(`${this._address}/users/me/subs`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: 'include',
      body: JSON.stringify({
        dateAcces, bonus, acces: true,
      })
  })
  .then(this._getResponseData)
}

 // Редактирование настроек пользователя
  updateuserSettings(data) {
    const settings = {
      sumFamily: data.capacity,
      periodAn: data.period,
      typeOfDiet: data.types,
      period: data.weekday,
      powerMode: data.meals,
      cookingTime: Number(data.values.minute),
      stopProducts: data.ingredientsData,
      kitchenware: data.kitchenware,
    }
    return fetch(`${this._address}/users/me/settings`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: 'include',
      body: JSON.stringify({settings})
  })
  .then(this._getResponseData)
}

// Добавление меню 
addMenuWeek(menuWeek) {
  return fetch(`${this._address}/plan`, {
    method: 'POST',
    headers: this._headers,
    credentials: 'include',
    body: JSON.stringify({ 
      date: menuWeek.date,
      breakfast: menuWeek.breakfast,
      dinner: menuWeek.dinner,
      snack: menuWeek.snack,
      foodList: menuWeek.foodList,
      lateDinner: menuWeek.lateDinner,
      supper: menuWeek.supper,
      lunch : menuWeek.lunch,
      title: 'Menu1',
    })
})
.then(this._getResponseData)
}

getFoodList(id) {
  console.log('getFoodList')
  return fetch(`${this._address}/foodlist/${id}`, {
    method: 'GET',
    headers: {
     'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
  .then(this._getResponseData)
}

// Добавление списка продуктов в базу 
addFoodList(foodList, data) {
  return fetch(`${this._address}/foodlist`, {
    method: 'POST',
    headers: this._headers,
    credentials: 'include',
    body: JSON.stringify({ 
      date: data,
      productList: foodList,
    })
})
.then(this._getResponseData)
}

// Обновление списка продуктов
updateFoodList(cardId, foodList) {
  return fetch(`${this._address}/foodlist/${cardId}`, {
    method: 'PATCH',
    headers: this._headers,
    credentials: 'include',
    body: JSON.stringify({ 
      productList: foodList,
    })
})
.then(this._getResponseData)
}

 //Получение списка меню
 getMenu () {
  return fetch(`${this._address}/plan`, {
    method: 'GET',
    headers: this._headers,
    credentials: 'include',
  })
  .then(this._getResponseData)
}

 //Получение списка меню
 getUserActivity () {
  return fetch(`${this._address}/users/userActivity`, {
    method: 'GET',
    headers: this._headers,
    credentials: 'include',
  })
  .then(this._getResponseData)
}

  //Получение  пользователей постранично
  getPage (url) {
    return fetch(`${this._address}/${url}`, {
      method: 'GET',
      headers: this._headers,
      credentials: 'include',
      })
    .then(this._getResponseData)
  }


//Обновление меню
updateMenuWeek(cardId, menuWeek) {
  return fetch(`${this._address}/plan/${cardId}`, {
    method: 'PATCH',
    headers: this._headers,
    credentials: 'include',
    body: JSON.stringify({ 
      date: menuWeek.date,
      breakfast: menuWeek.breakfast,
      dinner: menuWeek.dinner,
      snack: menuWeek.snack,
      foodList: menuWeek.foodList,
      lateDinner: menuWeek.lateDinner,
      supper: menuWeek.supper,
      lunch :menuWeek.lunch,
      title: 'Menu1',
     })
})
.then(this._getResponseData)
}

 //Удаление меню
 deleteMenuWeek(cardId) { 
  return fetch(`${this._address}/plan/${cardId}`, {
    method: 'DELETE',
    headers: this._headers,
    credentials: 'include'
  })
  .then(this._getResponseData)
}

//Добавление праздниччного меню
addHolidaysPlan(plan) {
  return fetch(`${this._address}/plan/holidays`, {
    method: 'POST',
    headers: this._headers,
    credentials: 'include',
    body: JSON.stringify({ 
      title: plan.title,
      capacity: plan.capacity,
      typeOfDiet: plan.types,
      category: plan.category,
      dinners: [],
      deserts: [],
      salads: [],
      snacks: [],
      cakes: [],
      drinks: [],
      foodList: [],
    })
})
.then(this._getResponseData)
}

} 

const api = new Api({
      //    address: 'http://localhost:3002',
     address: 'https://api.menubook.ru',
  headers: {
    'Content-Type': 'application/json'
}
});

export default api