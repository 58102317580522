//Разметка добавления приема пищи в план
import React from 'react';

export function AddMeals(props) {
 const item =props.item
  return (
    <p className='add-meals__item font16N' onClick={()=> props.onClick(item)}>
      Добавить {item.mealsRu}
    </p>
  )
}
