import React from 'react';
 
export function WeightPortion (props) {
 
  return (
    <>
    {props.total !== 0 &&
    <div className='portion__item font16Norm'>
      <span className="portion__name">Вес</span>
      <span className='portion__num'>{props.total}г</span>
    </div> 
    }

    </>
  )
}
