//Разметка страниц в рецептах

import React from 'react'; 
import './CounterPage.css';

export function CounterPage(props) {
  const pages = props.pages
  const step = 10
  let [start, setStart] = React.useState(0)
  let [end, setEnd] = React.useState(step) 
  let slicedArray = pages.slice(start, end); 
 
  //переключение для диапазонов страниц
  function handleClickBack() {
    setStart(start-step)
    setEnd(end-step) 
    props.handleCounter(start-1)
  }

  function handleClickForward() {
    setStart(start+step)
    setEnd(end+step)
    props.handleCounter(start+step+1) 
  }

  //переклюение для крайних значений страниц
  function handleCounterBack(page) {
    props.handleCounter(page)
    setStart(0)
    setEnd(step) 
  }

  function handleCounterForwards(page) {
    props.handleCounter(page)
    setStart(page-step)
    setEnd(page) 
  }

  return (
    <div className='pages'>
      <button type='button' className={`page-button  ${start === 0 ? 'visibility' : ''}  font16Norm`} onClick={handleClickBack}>Назад</button>
      <div 
        className={`page__number ${ start === 0 ? 'visibility' : ''}`} 
        onClick={()=> handleCounterBack(1)}>{1} </div>
      <span className={`page-dots font26Bold ${start === 0 ? 'visibility' : ''}`}>...</span>
      {slicedArray.map((page, index)=>  
        <div 
          className={`page__number ${props.currentPage === page ? 'page__current' : ''}`}
          key={index} 
          onClick={()=>props.handleCounter(page)}>{page}
        </div>  
      )} 
      <span className={`page-dots font26Bold ${end >= pages.length-1 ? 'visibility' : ''}`}>...</span>
      <div 
        className={`page__number ${end >= pages.length ? 'visibility' : ''}`} 
        onClick={()=>handleCounterForwards(pages.length)}>{pages.length}
      </div>  
      <button type='button' className={`page-button  ${end >= pages.length-1 ? 'visibility' : ''}  font16Norm`} onClick={handleClickForward}>Вперед</button>
    </div>
  ) 
}