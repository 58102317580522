import React from 'react';  
import { MenuItem, SceletonMenu } from "../index.js"; 
import { Slider } from '../../molecules/index.js'
import {useSelector, useDispatch} from 'react-redux' 
  
export function PlanSlides(props) {
  const items = props.items
  const [step, setStep] = React.useState(3)
  const [slide, setSlide] = React.useState(0);
  const nums = items.length %step === 0 ? items.length/step : (items.length+1)/step
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes)
  const todayMenu = items.sort(function(a, b) {
    return a.date - b.date;
  })
  function checkWindowWidth() {
    setTimeout(() => setWindowWidth(window.innerWidth), 500);
  }; 

  //Пролистывает слайды в зависимости от клика
  const goToSlide = (number) => {
    setSlide(number % nums);
  };

  React.useEffect(()=> {
    setSlide(0)
  }, [props.date])


  React.useEffect(()=> {
    window.addEventListener('resize', checkWindowWidth)
    if(window.innerWidth > 1024) {
        setStep(3);
      }  else if (window.innerWidth < 660) {
        setStep(1);
      } else if (window.innerWidth < 1023) {
        setStep(2);
      }
      return () => window.removeEventListener('resize', checkWindowWidth);

  }, [windowWidth]) 

  //Меняет последовательно слайды
  const changeSlide = (direction = 1) => {
    let slideNumber = 1;
    if (slide + direction < 0) {
      slideNumber = nums - 1;
    } else {
      slideNumber = (slide + direction)% nums
    }
    setSlide(slideNumber);
  };
  
  return (
    <section className='menu-sliders'>  
      <Slider changeSlide={changeSlide} step={step} nums={nums} title='plan' length={items.length} slide={slide} offset='100' goToSlide={goToSlide}>
        {recipeDataMenu.length > 0 ?
           todayMenu.map((item) => (
          <div className='slide' key={item.date}>
          <MenuItem  
            item={item}
            key={item._id} 
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe={props.handleDeleteRecipe}
            handleAutoReplace={props.handleAutoReplace}
            cardsPosition={props.cardsPosition}
            handleDeleteMenuDay={props.handleDeleteMenuDay}
            handleAddMenuItem={props.handleAddMenuItem}
            addRecipe={props.addRecipe}
            replaceRecipe={props.replaceRecipe}
            changeMenuDay={props.changeMenuDay}
            ingredients={props.ingredients}
            handleEdite = {props.handleEdite} 
            handleAddMeals={props.handleAddMeals}
            handleAutoReplaceMenu={props.handleAutoReplaceMenu}
            clickCreate={props.clickCreate}
            blockedSaveMenu={props.blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus} 
          />
           </div>
        ))
        : items.length === 0 ? '' : <SceletonMenu count={items.length}/> } 
      </Slider> 
   
    </section>
  )  
}