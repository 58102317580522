import React from 'react';

export function ImageItem (props) {
  function handleClick() {
    props.onClick(props.src)
  }
    
    return (
      <img className="image-medium__small" src={props.src} onClick={handleClick} alt={props.name} />
    )
}