import React from 'react';
import {Link} from 'react-router-dom'
import './Promo.css';
import promo from '../../../images/promo.png'
import {useSelector, useDispatch} from 'react-redux' 

export function Promo(props) {
  const { currentUser } = useSelector(state => state.currentUser) // данные пользователя
 
  return (
    <section className='promo color'>
      <div className='promo__info'> 
        <h1 className='promo__title font35Bold'>Составьте план питания в&nbsp;несколько кликов бесплатно!</h1>
        {Object.keys(currentUser).length == 0 
        ?
          <Link to='/signin' className="promo__link font26Norm">Войти</Link>
        :
          <Link to='/user/my-menu' className="promo__link font26Norm">Составить меню</Link>
        }
       
      </div>
       <img src={promo} alt='продукты на неделю' className='promo__image'/>
    </section>
  )  
}