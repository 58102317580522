//Верификация пользователя (Подтверждение email)

import React from 'react';
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux' 
import {useFormValidation} from '../../../utils/Validator.js';
import {pattern, subscriptions} from '../../../constants/constants.js';

function Reset(props) {
  const { lib, statusLib } = useSelector(state => state.lib) 
  const submitAuth = props.submitAuth;
  const { values, handleChange, errors, isValid, emailError, nameError, resetForm,
    passwordError, errorsConfirmation,
  } = useFormValidation({
    email: '', });
 
  let submitDisabled = values.email === '' || values.password === '' || !isValid || passwordError || errorsConfirmation || emailError;
  const { subs } = useParams();
  const subscription = subscriptions.find(i=> i.subs === subs)

  function handleSubmit(e) {
    e.preventDefault();
    props.onReset(values.email)  
  }  
  console.log(props.loading)
  return (
    <form className="form__info" onSubmit={handleSubmit} disabled={submitDisabled ? true : ''}>
        <fieldset className="form__data">
          <h1 className='font24Bold'>Востановление пароля</h1>
        <label className="input__label" htmlFor="email"></label>
          <input 
          autoComplete="off" 
          type="email" 
          onChange={handleChange} 
          value={values.email || ''} 
          placeholder="Введите Ваш Email" 
          className="form__item form__item_type_email" 
          name="email" 
          id="email" 
          pattern={pattern.email}
          required/>
          {props.errorsEmail && <span className="font16Norm form__item-error">{props.errorsEmail}</span>} 
          </fieldset>
        
        <button disabled={submitDisabled} className={`menuweek-button form__button color ${submitDisabled ? ('form__button_disabled') : props.loading ? 'form__button_loading' :'section__link'}`} type="submit" aria-label='reset'>
        <span class={`${props.loading ? 'button__text' : ''}`}>Отправить</span>
        </button> 
      </form>
  )
}
export default Reset;