import React from 'react';
import './InfoPopup.css'; 
 
export const InfoPopup = React.forwardRef((props, ref) => ( 
  <div ref={ref} className={`popup popup__${props.type} ${props.isOpen ? 'popup__open' : ''}`} >
    <div className={`triangle triangle__${props.type}`}>
      {props.children}
    </div>
     
  </div>  
));