//Инструкция

import React from 'react';
import './Manual.css';
import maket from '../../../images/maket.png';
import phone from '../../../images/phone.png';
import {advantages} from '../../../constants/constants.js'
import {List} from './List.js';
import table from '../../../images/gastro-slider.png';


export function Manual (props) { 
  return (
    <>
    <section className='manual'>
   
     <ul className='manual__text'>
     {advantages.map((i, index)=>(
         <List item = {i} key={index}/>
     ))}
    </ul>
    <div className='manual__content'>
      <img className='manual__maket' alt='Заполните настройки' src={maket} />
      <img className='manual__phone' alt='Заполните настройки' src={phone} /> 
     
    </div> 
    </section>
   
    </>
)
   
}