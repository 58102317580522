import React from 'react';
import { Cal } from '../../atoms/index.js'

export function Cals (props) {
  const calories = props.calories
  return (
    <div className='cals'>
       <Cal name='Вес' num={props.total} measure = 'г'/>
       <Cal name='Энергия' num={Math.ceil(calories.calories/props.servings)} measure = 'ккал'/>
       <Cal name='Белки' num={Math.ceil(calories.protein/props.servings)} measure = 'г'/>
       <Cal name='Жиры' num={Math.ceil(calories.fats/props.servings)} measure = 'г'/>
       <Cal name='Угдеводы' num={Math.ceil(calories.carb/props.servings)} measure = 'г'/> 
    </div>
  )
}