//Инпуты с возможностью единств выбора

import React from 'react';
import {useSelector } from 'react-redux'

export function Radio (props) {
  const { currentUser } = useSelector(state => state.currentUser)
  return (
    <div className='form-settings__block_row'>
    <label className='meals__label font20Norm' htmlFor='all'>
      <input type="radio" value={'all' || ''} checked={props.statusRec ==='all' ? true : false}  className='checkbox__input' onChange={props.onChange}  name='recipe' id='all' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Все рецепты
    </label>
    {props.feast &&
     <label className='meals__label font20Norm' htmlFor='feast'>
     <input type="radio" value={'feast' || ''} checked={props.statusRec ==='feast' ? true : false} className='checkbox__input' onChange={props.onChange}  name='recipe' id='feast' />
     <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
     Новогодние рецепты
   </label>}
   {Object.keys(currentUser).length !== 0 && 
   <>
   <label className='meals__label font20Norm' htmlFor='owner'>
      <input type="radio" value={'owner' || ''} checked={props.statusRec ==='owner' ? true : false} className='checkbox__input' onChange={props.onChange}  name='recipe' id='owner' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Мои рецепты
    </label>
    <label className='meals__label font20Norm' htmlFor='like'>
      <input type="radio" value={'like' || ''} checked={props.statusRec ==='like' ? true : false} className='checkbox__input' onChange={props.onChange}  name='recipe' id='like' />
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      Избранные рецепты
    </label>
    </>}
    
    
    </div>
  )
}
