//Авторизация пользователя

import React from 'react';
import Form from '../../organisms/Form/Form.js'
import './Login.css'
import {useFormValidation} from '../../../utils/Validator.js';

function Login(props) {
  const submitAuth = props.submitAuth;
  const { values, handleChange, isValid, emailError, 
 } = useFormValidation({
    email: '', password: '' });
  const submitDisabled = values.email === '' || values.password === '' || !isValid || submitAuth || emailError;
  console.log('login')
  //Отправка данных
  function handleSubmit(e) {
    e.preventDefault();
    props.onLogin(values.email, values.password);
  }  
  return (
    <Form 
    title="ВХОД" 
    buttonName="Войти" 
    text="Ещё не зарегистрированы?"
    sign="Регистрация"
    rout="/signup/standart"
    reset="/reset"
    onLogin={props.onLogin}
    email={values.email}
    password={values.password}
    onSubmit={handleSubmit}
    onChange={handleChange}
    errorsEmail={emailError}
    //errorsPassword={passwordError}
    submitDisabled={submitDisabled}
    serverError={props.serverError}
    >
    </Form>   
  )
}

export default Login;