//Подвал сайта

import React from 'react';
import './Footer.css'; 
import {Link, NavLink, } from 'react-router-dom'
import telegram from '../../../images/tg.png';
import vk from '../../../images/vk.png';
import table from '../../../images/gastro-slider.png';

export function Footer(props) {

  return (
    <footer className='footer color'>
       
       <p className="footer__copyright font20Norm">&copy;2023 MENUBOOK</p>
       <nav className="lk-menu lk-menu__footer">
        <ul className="lk-menu__list">
          <li className="lk-menu__link"><NavLink to='/recipes' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text"}`} >Рецепты</NavLink></li>
          <li className="lk-menu__link"><NavLink to='/feast' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text"}`} >Новогоднее меню</NavLink></li>
          <li className="lk-menu__link"><NavLink to='/user/my-menu' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text"}`} >Составить меню</NavLink></li>
         </ul>
      </nav>
      <nav className="footer__nav">
        <a className="footer__link section__link" href="https://t.me/menubook_ru" target="_blank" rel="noreferrer"><img className='social-img' src={telegram} /></a> 
        <a className="footer__link section__link" href="https://vk.com/public220892295" target="_blank" rel="noreferrer"><img className='social-img' src={vk} /></a> 
      </nav>
  </footer>
)
   
}
