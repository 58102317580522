//Дополнительно меню для авторизированных пользователей

import React from 'react';
import './SideFilter.css';
import { InputCheckbox,  } from '../../atoms/index.js' 
import { useSelector } from 'react-redux'

export function SideFilter(props) {
  const { statusLib } = useSelector(state => state.lib) 
   
    return (
      <>
      {statusLib === 'resolved' &&
      <form className={`col col__aside ${props.showFilterBlock ? 'col__sticky': ''}`} disabled={props.fetching === 'loading' ? true : ''}>
        <h2 className='col__title font20Bold'>Фильтры</h2>
        <button type='button' className='gg-closed' onClick={props.handleFilterBlock} />
        <fieldset className='checkbox-fieldset'>
          <legend className='col__aside_title font20Bold'>Тип питания</legend>
          {props.type.map((item, index)=> (
            <InputCheckbox item={item} key={index} fetching={props.fetching} onChange={props.handleChoiceType} type='typeFoods'/>
          ))} 
        </fieldset>
        <fieldset className='checkbox-fieldset'>
          <legend className='col__aside_title font20Bold'>Категория блюда</legend>
          {props.category.map((item, index)=> (
            <InputCheckbox item={item} key={index} fetching={props.fetching} onChange={props.handleChoiceCategory} type='dish'/>
          ))}
        </fieldset>
        {props.showFilterBlock &&
          <button type='button' className='menuweek-button font24Bold color' onClick={props.handleFilterBlock}>OK</button>
        }
      </form>
      }
    </>
  )  
}
  