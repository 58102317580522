//Форма настроек пользователя для меню

import React from 'react';
import './FormSettings.css'
import { TypesFood, InputIngredient, ExpectItem, KitchenwareSetting, InfoPopup, Ingredientpoint, RadioBox, InputCounter, Range} from '../../atoms/index.js';
import { ChoiceMeals } from '../index.js'
import { Preloader } from '../../organisms/index.js'

export function FormSettings(props) { 
  
  return (
    <section className={`form-settings form-settings_type_${props.type} `}> 
      <h1 className='form-settings__heading font35Bold'>Настройки меню</h1>
      {/*<p className='form-settings__title font24Bold'>Составьте до трех разных меню</p>
      <p className='form-settings__subtitle font24Norm'>Количество порций на каждое меню неограничено</p>*/}
      <p className='form-settings__subtitle font24Bold'>Заполните индивидуальные настройки</p>
      <form className='form-settings__form' onSubmit={props.onSubmit} autoComplete="off">
        <fieldset className='form-settings__fieldset form-settings__fieldset__cooking'> 
          <InputCounter
            handleCountClickMinus={props.handleCountClickMinus}
            handleCountClickPlus={props.handleCountClickPlus}
            disabled={props.isDisabled}
            name='capacity'
            onChange={props.onChange}
            value={props.capacity}
            title='Количество порций'
          /> 
          <div className='form__block form__block_time' htmlFor='time'>
            <legend className='form-settings__title font20Bold'>Время приготовления</legend>
            <label className='form-settings__time'> до
              <input type='number' value={props.minute || ''} onChange={props.onChange} min="10" max="180" className='form-settings__input form-settings__input_time' name='minute' id='minute'/>
                <span>минут</span>
            </label>
            {(props.hours ==='' && props.minute ==='') && <span className="add-form__item-error">Поле не может быть пустым</span>}
          </div>
        </fieldset>
        <fieldset className='form-settings__fieldset'>
          <legend className='form-settings__title font24Bold'>Тип питания</legend>
          <TypesFood handleCheckBox={props.handleCheckBox} types={props.types}/>
            </fieldset> 
            <fieldset className='form-settings__fieldset'>
              <legend className='form-settings__title font24Bold'>  Как часто Вы готовите?</legend>
              <RadioBox radio={props.period} onChange={props.handleCheckBoxPeriod}/>
            </fieldset>
            <fieldset className='form-settings__fieldset '>
              <legend className='form-settings__title font24Bold'> Выберите приемы пищи</legend>
              <div className='form-settings__graph'>
                <ChoiceMeals types={props.meals} settingDinner={props.settingDinner} handleCheckBox={props.handleCheckBoxMeals}/>
              </div>
            </fieldset>
            <fieldset className='form-settings__fieldset'>
              <legend className='form-settings__title font24Bold'> Продукты, которые следует исключить из меню.</legend> 
              <div className='form-settings__block'>
              <p className='foodlist__paragraph font24Norm'> Выберите группу или введите продукты по отдельности</p>
              <ExpectItem handleExpectCheck={props.handleExpectCheck} exceptions={props.exceptions} ingredientsData={props.ingredientsData} types={props.except}/>
                <InputIngredient 
                  handleChangeInput={props.handleChangeInput}
                  handleAutoText={props.handleAutoText}
                  filtrMassiv={props.filtrMassiv}
                  hidden={props.hidden}
                  ingredient={props.ingredient}
                  ingredientsData={props.ingredientsData}
                />
                  <button 
                  type='button' 
                  onClick={props.handleAddIng} 
                  disabled={props.addIngDisabled} 
                  className={`menuweek-button button__settings font20Norm color ${props.addIngDisabled ? ('add-form__button_disabled') : ''}`}
                >
                  Добавить ингредиент
                </button>
                <div className='form-settings__list'>
                  {props.ingredientsData.length > 0 && //список добавленных ингридиентов
                    props.ingredientsData.map((item, index)=> (
                      <Ingredientpoint key={index} item={item}  onDelete={props.onDelete}/>
                    ))
                  }
                </div> 
              </div>
            </fieldset> 
            <fieldset className='form-settings__fieldset'>
              <legend className='form-settings__title font24Bold'> Оборудование на кухне</legend>
              <div className='form-settings__kitchenware'>
                {props.kitchenware.length> 0 &&
                props.kitchenware.map((item)=> (
                  <KitchenwareSetting  handleChange={props.handleCheckBoxKitchenware} key={item._id} item={item} />
                ))
              
              }
              </div>
            </fieldset>
            <fieldset className='form-settings__fieldset form-settings_cals'>
              <legend className='form-settings__title font24Bold'> Суточная калорийность на человека</legend>
              <div className='product-item__head'>    
                <label className='product-item__label'>
                  <input 
                    type="checkbox" 
                    checked={props.choice}
                    onChange={props.handleChoice}
                    className='product-item__choice' 
                    name="choice" 
                    id="choice"
                  />
                  <span className="checkbox-green item__link"></span>
                </label>
                <p className='font20Norm'>Не учитывать калорийность блюд</p>
              </div>   
               <Range disabled={props.choice} calories={props.calories} onChange={props.onChange}/>
            </fieldset> 
            <div className="buttons__block">
              {props.statusUser === 'resolved' ? 
                <>
                <button type='submit' disabled={props.submitDisabled} className={`form-settings__submit menuweek-button font20Norm color ${props.submitDisabled ? ('add-form__button_disabled') : ''}`}>Сохранить</button>
                {props.submitDisabled ?
                  <button type='button' onClick={props.handleClickAttention} className='attention__icon m-size item__link'>
                    <InfoPopup
                      isOpen={props.isHovering}
                    >
                      {props.minute === undefined || props.minute < 2 ?
                        <span className="add-form__item-error left-error">Заполните время приготовления? Время не должно быть меньше 2 минут  </span>
                        : props.capacity === undefined || props.capacity === 0 
                        ? <span className="add-form__item-error left-error"> Выберите минимальное количество порций</span>
                        : !props.typesDisabled 
                        ? <span className="add-form__item-error left-error">Выберите тип питания</span>
                        : !props.mealsDisabled && <span className="add-form__item-error left-error">Выберите хотя бы 1 прием пищи</span>
                      }
                    </InfoPopup> 
                  </button>
                  : ''}
                </> 
              : <Preloader /> 
              } 
            </div>
        </form>
    </section>
  )
}
