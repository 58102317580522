//Страница со списком рецептов

import React from 'react';
import './Recipes.css';
import {useSelector, useDispatch } from 'react-redux'
import { Recipe, AddEditRecipe, SceletonRecipe, SideFilter, SceletonSideFilter, CounterPage, ModalChoiceAsk } from '../../organisms/index.js'
import { Search, SearchByIngredient } from '../../molecules/index.js'
import { FilterRecipe } from './FilterRecipe.js'
import { useNavigate, createSearchParams } from 'react-router-dom' 
import { filtrSearchArr } from '../../../utils/utils.js'
import { fetchFilter, deleteRecipe } from '../../../store/api.js' 
import { Button } from 'antd';
import { Helmet } from 'react-helmet-async';

export function Recipes(props) {
  let navigate = useNavigate() 
  const dispatch = useDispatch();
  const { lib, statusLib } = useSelector(state => state.lib)
  const {recipePage, totalCount, fetching} = useSelector(state => state.recipes) 
  const {ingredients, status} = useSelector(state => state.ingredients) 
  const [showModal, setShowModal] = React.useState(false)
  const [showFilterBlock, setShowFilterBlock] = React.useState(false)
  const [showModalEdit, setShowModalEdit] = React.useState(false)
  const [editCard, setEditCard] = React.useState(false)  
  let filters = JSON.parse(localStorage.getItem('filters'));
  const [category, setCategory] = React.useState([])
  const [type, setType] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1) 
  //const [fetching, setFetching] = React.useState(false) 
  const [choiceIngredient, setChoiceIngredient] = React.useState([]) 
  const limit = 15
  const allPages = Math.ceil(totalCount/limit)
  const pages = Array.from({length: allPages}, (_, i) => i + 1) 
  const [keyword, setKeyword] = React.useState('')   
  const [keywords, setKeywords] = React.useState('')
  const [hidden, sethidden] = React.useState(true)          
  const [filtrMassiv, setFiltrMassiv] = React.useState([]);
  const [inputTimer, setInputTimer] = React.useState(null); 
  const submitDisabled = filterSearch() || keyword || parametrkeywords();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedCardDel, setSelectedCardDel] = React.useState(false);
  const parametrSearch = {
    currentPage: currentPage,
    params : filterSearch(),
    searchWords: keyword,
    ingredient : parametrkeywords(),
    limit: limit,
    like: '',
    owner: '',
    feast: '',
  } 
                              ////* Постраничная пагинация *//////
  // Запрос данных из библиотеки
  React.useEffect(()=> { 
    if(filters !== null) {
      setType(filters.type)
      setCategory(filters.category)
    }
  },[])

  // Запрос данных из библиотеки
  React.useMemo(()=> { 
    if(!filters && statusLib === 'resolved') {
      setCategory(lib.dish)
      setType(lib.typeFoods) 
    }
  }, [lib])
                                 
  // Проверка условий для запроса на сервер                
  React.useEffect(()=> { 
    if(currentPage && category.length> 0 && type.length> 0) {
      console.log('ok')
      //setFetching(true) 
      dispatch(fetchFilter(parametrSearch))
    } 
  },[currentPage,category, type, choiceIngredient])

  React.useEffect(()=> { 
    if(currentPage && category.length> 0 && type.length> 0) {
     // setFetching(true) 
      clearTimeout(inputTimer);
    let timeout = setTimeout(() => {
      parametrSearch.searchWords = keyword 
      dispatch(fetchFilter(parametrSearch))
    }, 1000);
    setInputTimer(timeout);
     
    } 
  },[keyword])
 
  // Сброс счетчика страниц на 1 при фильтрации               
  React.useEffect(()=> { 
    setCurrentPage(1)
    const params = filterSearch()  
    if(params) {
      navigate({
        pathname: '/recipes/',
        search: `?${createSearchParams(params)}`,
      });
    } else {
      navigate({
        pathname: '/recipes/', 
      });
    } 
    if(type.length > 0 || category.length > 0) { 
      localStorage.setItem(
        "filters",
        JSON.stringify({type : type, category : category})
      );
    }
  },[category, type])

  // При переключении страниц скрол наверх
  React.useMemo(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },[currentPage])

  //Видимость фильтров 
  React.useEffect(() => {
    if(showFilterBlock) {
      document.body.classList.add('hidden'); 
    } else {
      document.body.classList.remove('hidden');
    } 
  }, [showFilterBlock]);

  //Счетчик изменения страниц
  function handleCounter(page) {
    //setFetching(true)
    setCurrentPage(page)
  } 
  
  function handleDeleteRecipe(recipe) {
    dispatch(deleteRecipe(recipe._id))
    if(fetching === 'resolved') {
      setIsOpen(false) 
    } 
  }
  function handleOpenModal(recipe) {
    setIsOpen(true) 
    setSelectedCardDel(recipe)
  }

  //Создание поисковой строки для фильтров
  function filterSearch() {
    let params='' 
    if(type.length > 0 && category.length > 0) {
      type.map(s => {
        if(s.boolean) {
          params = params + `&types[]=${s.nameEn}`

        }
      }) 
      category.map(s => {
        if(s.boolean) {
          params = params + `&dishes[]=${s.nameRu}`
        }
      })
    } 
    return params
  }

  //Функция поиска по названию рецепта
  function handleSearchDish(e) {
    setKeyword(e.target.value) 
  } 

  function parametrkeywords() {
    let param=''
    choiceIngredient.map(i=> {
      param = param + `&keywords[]=${i._id}`
    })
    return param
  }
  //Изменение стейта открытия модального окна редактирования рецепта
  function handleEditRecipe(recipe) {
    setShowModalEdit(true)
    setEditCard(recipe)
  }

  //закрытие всех модалок
   function handleCloseModal() {
    setShowModal(false)
    setIsOpen(false)
    setShowModalEdit(false)
    setEditCard(false)
  }

  function handleChoiceCategory(item) { 
    setCategory(() => category.map((c) => c.nameEn === item.nameEn ? item : c))
  }
  console.log(type)
  function handleChoiceType(item) { 
    console.log(item)
    setType(() => type.map((c) => c.nameEn === item.nameEn ? item : c)) 
  }
 
  function handleFilterBlock() {
    setShowFilterBlock(!showFilterBlock)
  }
 
  function handleDelete(i) {
    setChoiceIngredient((item)=> item.filter((c) => c._id !== i)) 
  }

  function handleAutoText(ing) {
    setChoiceIngredient([...choiceIngredient, {_id: ing._id, name: ing.name}])
    setKeywords('')
    sethidden(false)
  }
  function handleChangeInput(e) {
    handleFiltrClick(e.target.value)
    setKeywords(e.target.value);
    //setKeyword('')
    //errorMessage(e)
    sethidden(true)
  }

  function handleReset(e) {  
    setType(lib.typeFoods)
    setCategory(lib.dish)
    setChoiceIngredient([])
    setKeyword('')
    localStorage.removeItem('filters')
   
  } 

  function handleFiltrClick(keyword) {
    if(keyword!=='') {
      const newMassiv = filtrSearchArr(ingredients, keyword, 'ingredient')
      setFiltrMassiv(newMassiv)
      } else {
        setFiltrMassiv([])
      }
  }  
 
  return (
    <div> 
    <Helmet>
      <title>Рецепты Кето, Вегетарианские , Детские, Столы 1 2, ПП, Без глютена</title>
      <meta name="description" content='Здесь Вы найдете Кето рецепты, Вегетарианские рецепты , Детские рецепты, Столы 1 2, ПП рецепты, рецепты Без глютена, а также составить меню из них.'/> 
    </Helmet>
      <div className='recipes'> 
        <div className='recipes__head'> 
          <h1 className='recipe__title font35Bold'>Рецепты</h1>  
          <div className='filters__button color menuweek-button' onClick={handleFilterBlock}>
            <span className=' gg-filter l-size'></span>
            <span>Фильтры</span>
          </div>   
        </div>
      <div className='row'>
      {status=== 'resolved' ?
        <SideFilter 
          type={type}
          category={category}
          fetching={fetching}
          handleChoiceCategory={handleChoiceCategory}
          handleChoiceType={handleChoiceType}
          scrollTop={props.scrollTop}
          showFilterBlock={showFilterBlock}
          handleFilterBlock={handleFilterBlock}
        />
        : <SceletonSideFilter />
      }
      <section  className={`col col__content ${fetching=== 'loading' ? 'col__search' : props.scrollTop > 0 ? 'col__article_sticky': ''}`}> 
          <form className='recipes__search' >
            <Search onSearch={handleSearchDish} keyword={keyword} text='по рецептам' placeholder='Искать по названию'/>
            <SearchByIngredient 
              handleAutoText={handleAutoText} 
              handleDelete={handleDelete} 
              hidden={hidden}
              keywords={keywords}
              filtrMassiv={filtrMassiv}
              handleChangeInput={handleChangeInput}
              choiceIngredient={choiceIngredient}
              text='по ингредиентам' 
              placeholder='Искать по ингредиентам'
            />
            <FilterRecipe handleDelete={handleChoiceType} handleDeleteCat={handleChoiceCategory} type={type.filter(t=> t.boolean)} category={category.filter(c=> c.boolean)} />
            <Button type='button' className={`menuweek-button ${submitDisabled ? 'color' : ''}`} onClick={handleReset}>Сбросить фильтры</Button>
            </form>
          {recipePage.length > 0  ?
          <div className='col col__article'>
            {recipePage.map((item)=> (
              <Recipe 
                onCardLike={props.onCardLike} 
                editRecipe={handleEditRecipe} 
                loggedIn={props.loggedIn}
                recipe={item} 
                key={item._id}
                isOpen={isOpen}
                handleDelete={handleOpenModal}
                addRecipe={props.addRecipe} 
                replaceRecipe={props.replaceRecipe} 
                replace={props.replace} 
                closeModal={props.closeModal} 
                positionMenuItem={props.positionMenuItem}
              />
            )) 
            }
             </div>
              : fetching === 'resolved' && recipePage.length === 0 ?
              <p className='recipes__error'>Рецепты соответствующие запросу не найдены</p>
              :
              <div className='col col__article'>
              <SceletonRecipe />
              </div>
            }
             <CounterPage handleCounter={handleCounter} pages={pages} currentPage={currentPage} totalCount={totalCount}/>
        </section>
       
      </div>
    </div>
      <AddEditRecipe 
        onClose={handleCloseModal} 
        imageError={props.imageError} 
        card={editCard && editCard} 
        isEditOpen={showModalEdit} 
        isOpen={showModal} />
        <ModalChoiceAsk 
          isOpen={isOpen} 
          onClose={handleCloseModal} 
          onClick={handleDeleteRecipe} 
          cards={selectedCardDel !==null && selectedCardDel} 
        />
    </div>
  )  
}
 