import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'dataSlice',
    initialState: {
      dataSlice: [],
      random:'',
    },
    reducers: {
      addRandomNumber(state, action) {
        state.random(action.payload)
      },
      
    }
    
  })

  export const { addRandomNumber } = dataSlice.actions;

