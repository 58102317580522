import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import './UserCard.css';  
import {pattern} from '../../../constants/constants.js';
import {useFormValidation} from '../../../utils/Validator.js';
import { getRecipeLike } from '../../../store/api.js'
import {UserInfo, Recipe} from '../index.js'
import avatar from '../../../images/favicon.png'
import Resizer from "react-image-file-resizer";

export function UserCard(props) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.currentUser)
  const {recipeLike, statusLike} = useSelector(state => state.recipes)
  const { values, handleChange, errors, isValid } = useFormValidation({
    login: currentUser.login ? currentUser.login : '', email: currentUser.email, phone: currentUser.phone ? currentUser.phone : '', name:  currentUser.name});
  const submitDisabled = values.email === '' || values.name === '' || !isValid; 
  const [isDisabled, setIsDisabled] = React.useState(true);

  React.useEffect(() => {
    if(Object.keys(currentUser).length !== 0) {
      dispatch(getRecipeLike(currentUser._id))
    }
  },[currentUser]) 
  
  function handleEditProfile() {
    setIsDisabled(false)
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true)
    console.log('click')
    props.onEditProfile(values.email, values.name, values.phone, values.login, currentUser.acces)
  }
 
  const handleAddPhoto = async (e) => { 
    let photo
    try{ 
        const file = e.target.files[0];
        const image = await resizeFile(file);
        photo=image
      
    } catch (err) {
      console.log(err)
    }
    props.onAddPhoto(photo)
  }

  //сжатие и изменение формата изображения
  const resizeFile = async (file) => {
    return new Promise(resolve => {
      Resizer.imageFileResizer(
        file, 
        600, 
        600,  
        'JPEG', 
        70, 
        0,
        uri => {
          resolve(uri);
        }, 'base64' )
      });
  };
 
  return (
    <>
    { Object.keys(currentUser).length !== 0 &&
    <div className='user-card'>
      <section className='user-card__data'>
        <h2 className="user-card__title font35Bold">Мои данные</h2>
        <div className='user-card__info'>
          <div className="user-card__photo">
            <img className="user-card___image" src={currentUser.photo === '' ? avatar : currentUser.photo} alt="Мое фото" />
            <label className='photo__label' htmlFor='photo'>
              <input type='file' onChange={ handleAddPhoto} className="user-card__add-button font24Norm"  name='photo' id='photo'></input>
           <span className='gg-upload x-size visible-button'>Заменить</span>
           </label>
          </div>
          <form className="user-card__form" onSubmit={handleSubmit} >
            <fieldset className="user-card__form_fieldset">
              <label className="user-card__form_label font24Norm" htmlFor="login">Логин</label>
              <input 
              autoComplete="off" 
              disabled={isDisabled} 
              value={values.login}
              onChange={handleChange}
              type="text" 
              className="user-card__form_input font24Norm" 
              name="login" 
              id="login" 
              required 
              minLength="2" 
              maxLength="40"
              pattern={pattern.name}
             /> 
            <span className="email-error form__item-error"></span>
            </fieldset>
            <fieldset className="user-card__form_fieldset">
            <label className="user-card__form_label font24Norm" htmlFor="name">Имя   </label>
            <input 
            autoComplete="off" 
            disabled={isDisabled} 
            value={values.name}
            onChange={handleChange}
            type="text" 
            className="user-card__form_input font24Norm" 
            name="name" 
            id="name" 
            required 
            minLength="2" 
            maxLength="40"
            pattern={pattern.name}
             /> 
            <span className="email-error form__item-error"></span>
          </fieldset>
          <fieldset className="user-card__form_fieldset">
            <label className="user-card__form_label font24Norm" htmlFor="email">Почта</label>
            <input 
            autoComplete="off" 
            disabled={isDisabled} 
            type="email" 
            value={values.email} 
            onChange={handleChange}
            className={`user-card__form_input font24Norm ${!isDisabled ? ('user-card__form_input_active') : ''} `} 
            name="email" 
            id="email" 
            required
            pattern={pattern.email}
            />
          </fieldset>
          <fieldset className="user-card__form_fieldset">
            <label className="user-card__form_label font24Norm" htmlFor="phone">Телефон    </label>
            <input 
            autoComplete="off" 
            disabled={isDisabled} 
            type="phone" 
            value={values.phone} 
            onChange={handleChange}
            className={`user-card__form_input font24Norm ${!isDisabled ? ('user-card__form_input_active') : ''} `} 
            name="phone" 
            id="phone" 
            required
            pattern={pattern.phone}
            />
          </fieldset>    
       
          <div className="user-card__form__submit">
            {isDisabled ?
            <button onClick={handleEditProfile} className="user-card__form__button font20Norm menuweek-button color" type="button">Редактировать</button>
            : 
            <>
             {errors.email && <span className="password-error form__item-error">{errors.email}</span>}
            {errors.name && <span className="password-error form__item-error">{errors.name}</span>} 
            <button type="submit" disabled={submitDisabled ? true : ''}  className={`menuweek-button color font20Norm ${submitDisabled ? ('form__button_disabled') : 'section__link'}`}  >Сохранить</button>
          </>
          }
        </div>
        </form>
        </div>  
       
    </section>

    <section className='user-card__likes'>
    <UserInfo />

    </section>
    {statusLike=== 'resolved' && recipeLike.length> 0 && 
    <section className='user-card__likes'>
    <h2 className="user-card__title font35Bold">Избранные рецепты</h2>
      <div className='user-card__likes'> 
         { recipeLike.map((item)=> (
            <Recipe recipe={item} loggedIn={props.loggedIn} onCardLike={props.onCardLike} key={item._id}/>
          ))
         }
      </div>
    </section>
     }
    </div>
}
</>
  )  
}