import { createSlice } from '@reduxjs/toolkit'; 
import { 
  getIngredients, 
  deleteIngredients, 
  editIngredients, 
  changeLikeRecipe, 
  postRecipe, 
  editRecipe, 
  getFoodList,  
  updateUser,
  getRecipe,  
  deleteRecipe,
  postIngredients, 
  getRecipeId,
  getRecipeOwnerData,
  getLibrary,
  updateFoodList,
  postFoodList,
  deleteFoodList,
  getPlan,
  postPlan,
  updatePlan,
  deletePlan,
  getUser,
  fetchFilter,
  getRecipeData,
  fetchSuite,
  updatePlanFood,
  getRecipeOwner,
  getRecipeLike,
} from './api.js'

//Список ингредиентов
export const reguestSlice = createSlice({
    name: 'ingredients',
    initialState: {
      ingredients: [],
      status: null,
      error: null,
    },
    reducers: {},
    extraReducers: {
      [getIngredients.pending]: (state) => {
        state.status = 'loading';
        state.error = null;
      },
      [getIngredients.fulfilled]: (state, action)=> {
        state.status = 'resolved'
        state.ingredients = action.payload;
      },
      [postIngredients.fulfilled]: (state, action)=> {
        state.status = 'resolved'
        state.ingredients.unshift(action.payload)
      },
      [deleteIngredients.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.ingredients = state.ingredients.filter(ingredients => ingredients._id !== action.payload._id);
      },
      [editIngredients.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.ingredients = state.ingredients.map(ingredients => ingredients._id === action.payload._id ? action.payload : ingredients);
      },
      [getIngredients.rejected]: (state, action)=> {
        state.status = 'rejected'
        state.ingredients = action.payload;
      },
    }
  })
  
  //Данные для 1 рецепта при открытии карточки
export const reguestRecipeIdSlice = createSlice({
    name: 'recipe',
    initialState: {
      recipe: {},
      statusRecipe: null,
      statusLike: null,
      errorRecipe: null,
    },
    reducers: {},
    extraReducers: {
      [getRecipeId.pending]: (state) => {
        state.statusRecipe = 'loading';
        state.errorRecipe = null;
      },
      [getRecipeId.fulfilled]: (state, action)=> {
        state.statusRecipe = 'resolved';
       
        state.recipe = action.payload;
      },
      [changeLikeRecipe.pending]: (state) => {
        state.statusLike = 'loading';
        state.errorRecipe = null;
      },
      [changeLikeRecipe.fulfilled]: (state, action)=> {
        state.statusLike = 'resolved';
        state.recipe= action.payload;
      },
      [editRecipe.pending]: (state) => {
        state.statusRecipe = 'loading';
        state.errorRecipe = null;
      },
      [editRecipe.fulfilled]: (state, action)=> {
        state.statusRecipe = 'resolved'
        state.recipe = action.payload
      },
      [editRecipe.rejected]: (state, action)=> {
        state.statusRecipe = 'rejected' 
        state.errorRecipe = action.error.message; 
        
      }
    }
  })

  //Рецепты (список)
  export const reguestGetSlice = createSlice({
    name: 'recipes',
    initialState: {
      recipes: [], 
      recipesFiltr: [],
      recipeSearch: [],
      recipePage: [],
      recipeDataMenu: [],
      ownerRecipe: [],
      count: 0,
      recipeLike: [],
      types: [],
      dishes: [],
      filterObj: {},
      userSettingRecipe: [],
      randomRecipe: '',
      random: [],
      totalCount: 0,
      sts: null,
      statusLike: null,
      statusOwner: null,
      statusMenu: null,
      fetching: null,
      error: null,
      errorPage: null,
    },
    reducers: {
      searchRecipe(state, action) {
        const { cookingTime, typeOfDiet, stopProducts } = action.payload
        const typeOfDietArr = typeOfDiet.filter(s => s.boolean)
        state.userSettingRecipe = state.recipes.filter(k => k.ingredients.every(item => !stopProducts.includes(item._id)) 
        && (k.time < cookingTime || k.time === cookingTime) &&  k.typeOfDiet.some(item => typeOfDietArr.some((i)=> item.nameEn === i.nameEn && item.boolean)))
      },
      addFilterRecipes(state) {
        state.recipesFiltr = state.recipes
      },
      searchFilterRecipes(state, action) {
        state.recipesFiltr = action.payload
      },
      searchWord(state, action) {
        state.recipesFiltr.push(action.payload)
      },
      checkFilterRecipes(state, action) {
      
        const { typeBoolean, categoryBoolean } = action.payload 
        
        if(typeBoolean.length === 0 && categoryBoolean.length === 0) {
          state.recipesFiltr =  state.recipes 
          state.dishes = []
          state.types = []
         } else if(categoryBoolean.length !== 0 && typeBoolean.length === 0) {
           state.recipesFiltr =  state.recipes.filter((item)=> categoryBoolean.some(c=> c.nameRu === item.category && c.boolean) )
           state.dishes = categoryBoolean.map(i=> i.nameEn) 
         } else if(categoryBoolean.length !== 0 && typeBoolean.length !== 0) {
          state.recipesFiltr = state.recipes.filter((item)=> item.typeOfDiet.some( k => typeBoolean.some(t=> k.nameRu === t.nameRu && k.boolean) ) 
          && categoryBoolean.some(c=> c.nameRu === item.category && c.boolean))
          state.types = typeBoolean.map(i=> i.nameEn)
          state.dishes = categoryBoolean.map(i=> i.nameEn)
         } else if(categoryBoolean.length === 0 && typeBoolean.length !== 0) {
          state.recipesFiltr =  state.recipes.filter((item)=> item.typeOfDiet.some( k => typeBoolean.some(t=> k.nameRu === t.nameRu && k.boolean) ) )
          state.types = typeBoolean.map(i=> i.nameEn)
         } 

      },
      setFilter(state, action) {
        state.types = action.payload.types
        state.dishes = action.payload.dishes
      },
    },
    extraReducers: {
      [getRecipe.pending]: (state) => {
        state.sts = 'loading';
        state.error = null;
      },
      [getRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = action.payload
        state.totalCount = Number(action.payload.count)
        state.recipesFiltr = action.payload
      },
      [getRecipeLike.pending]: (state) => {
        state.statusLike = 'loading';
        state.error = null;
      },
      [getRecipeLike.fulfilled]: (state, action)=> {
        state.statusLike = 'resolved'
        state.recipeLike = action.payload
      },
      [getRecipeOwner.pending]: (state) => {
        state.statusOwner = 'loading';
        state.error = null;
      },
      [getRecipeOwner.fulfilled]: (state, action)=> {
        state.statusOwner = 'resolved'
        state.ownerRecipe = action.payload.data
        state.count = Number(action.payload.count)
      },
      [postRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes.push(action.payload)
        state.ownerRecipe.unshift(action.payload)
      },
      /*[changeLikeRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = state.recipes.map(recipe => recipe._id === action.payload._id ? action.payload : recipe); 
      },*/
      [deleteRecipe.pending]: (state, action)=> {
        state.fetching = 'loading'
        state.errorPage = null;
      },
      [deleteRecipe.fulfilled]: (state, action)=> {
        state.fetching = 'resolved' 
        state.recipePage = state.recipePage.filter(recipe => recipe._id !== action.payload._id);
        state.ownerRecipe = state.ownerRecipe.filter(recipe => recipe._id !== action.payload._id);
      },
      [editRecipe.fulfilled]: (state, action)=> {
        state.sts = 'resolved'
        state.recipes = state.recipes.map(recipe => recipe._id === action.payload._id ? action.payload : recipe);
      },
      [fetchFilter.pending]: (state) => {
        state.fetching = 'loading';
        state.errorPage = null;
      },
      [fetchFilter.fulfilled]: (state, action)=> {
        state.fetching = 'resolved' 
        state.totalCount = Number(action.payload.count)
        state.recipePage = action.payload.data
      },
      [fetchFilter.rejected]: (state, action)=> {
        state.fetching = 'rejected' 
        state.totalCount = 0
        state.recipePage =[]
      },
      [getRecipeData.pending]: (state) => {
        state.statusMenu = 'loading';
      },
      [getRecipeData.fulfilled]: (state, action)=> {
        state.statusMenu = 'resolved' 
        state.recipeDataMenu.push(...action.payload)
      },
      [fetchSuite.pending]: (state) => {
        state.statusMenu = 'loading';
      },
      [fetchSuite.fulfilled]: (state, action)=> {
        state.statusMenu = 'resolved' 
        state.recipeDataMenu.push(action.payload)
      },
    }
  })
 
  //Список продуктов
  export const reguestGetProductSlice = createSlice({
    name: 'productList',
    initialState: {
      productList: [], 
      statusProductList: null,
      error: null,
    },
    reducers: {
      
    },
    extraReducers: {
      [getFoodList.pending]: (state) => {
        state.statusProductList = 'loading';
        state.error = null;
      },
      [getFoodList.fulfilled]: (state, action)=> {
        state.statusProductList = 'resolved'  
        state.productList = action.payload;
      },
      
      [postFoodList.pending]: (state) => {
        state.statusProductList = 'loading';
        state.error = null;
      },
      [postFoodList.fulfilled]: (state, action)=> {
        state.statusProductList = 'resolved'
        state.productList = action.payload
      },
      [updateFoodList.pending]: (state) => {
        state.statusProductList = 'loading';
        state.error = null;
      },
      [updateFoodList.fulfilled]: (state, action)=> {
        state.statusProductList = 'resolved'
        state.productList = action.payload
        //state.productList = state.productList.map(product => product._id === action.payload._id ? action.payload : product)
      },
      [deleteFoodList.pending]: (state) => {
        state.statusProductList = 'loading';
        state.error = null;
      },
      [deleteFoodList.fulfilled]: (state, action)=> {
        state.statusProductList = 'resolved'
        state.productList = {}
      },
    }
  })

  //изменения в списке продуктов по продуктам
  export const reguestProductSlice = createSlice({
    name: 'product',
    initialState: {
      product: [],
      statusProduct: null,
      error: null,
    },
    reducers: {
      addProduct(state, action) {
        state.product = [...action.payload]
      },
    },
    extraReducers: {
      [postFoodList.pending]: (state) => {
        state.statusProduct = 'loading';
        state.error = null;
      },
      [postFoodList.fulfilled]: (state, action)=> {
        state.statusProduct = 'resolved'
        state.product = action.payload;
      },
      [updateFoodList.pending]: (state) => {
        state.statusProduct = 'loading';
        state.error = null;
      },
      [updateFoodList.fulfilled]: (state, action)=> {
        state.statusProduct = 'resolved'
        state.product = action.payload;
      }, 
      
    }
  })

  export const reguestLibrarySlice = createSlice({
    name: 'lib',
    initialState: {
      lib: [],
      statusLib: null,
      error: null,
      statusPost: null,
      errorPost: null,
    },
    reducers: {},
    extraReducers: {
      [getLibrary.pending]: (state) => {
        state.statusLib = 'loading';
        state.error = null;
      },
      [getLibrary.fulfilled]: (state, action)=> {
        state.statusLib = 'resolved' 
        state.lib = action.payload;
      },
    }
  })

  //Работа с планом питания
  export const reguestPlanSlice = createSlice({
    name: 'plans',
    initialState: {
      plans: [], 
      statusPlan: null,
      error: null,
      creature: false
    },
    reducers: {
    },
    extraReducers: {
      [getPlan.pending]: (state) => {
        state.statusPlan = 'loading';
        state.error = null;
        state.creature = true
      },
      [getPlan.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved'
        state.plans = action.payload;
        state.creature = false
      },
      [postPlan.pending]: (state) => {
        state.statusPlan = 'loading';
        state.error = null;
        state.creature = true
      },
      [postPlan.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved' 
        state.plans.push(...action.payload)
        state.creature = false
      },
      [updatePlan.pending]: (state) => {
        state.statusPlan = 'loading';
        state.error = null;
      },
      [updatePlan.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved'
        //state.plans = state.plans.map(plan => plan._id === action.payload._id ? action.payload : plan)
        state.plans = state.plans.map(plan => {
          const up = action.payload.find(a => a._id === plan._id)
          if(up !== undefined) {
            return up
          } else {
            return plan
          }
        });
      },
      [updatePlanFood.pending]: (state) => {
        state.statusPlan = 'loading';
        state.error = null;
      },
      [updatePlanFood.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved'
        state.plans = state.plans.map(plan => plan._id === action.payload._id ? action.payload : plan)
      },
      [deletePlan.fulfilled]: (state, action)=> {
        state.statusPlan = 'resolved'
        state.plans = state.plans.filter(plan => plan._id !== action.payload._id);
      },
 
    }
  })

  export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: {
      currentUser: {}, 
      servings: 1,
      loggedIn: null,
      recipeOwner: '',
      statusOwner: null,
      statusUser: null,
      errorUser: null, 
      statusTextUser: ''
    },
    reducers: { 
      infoDelete(state, action) {
        state.statusTextUser = '' 
      },
    }, 
    extraReducers: {
      [getUser.pending]: (state) => {
        state.statusUser = 'loading';
        state.errorUser = null;
      },
      [getUser.fulfilled]: (state, action)=> {
        state.statusUser = 'resolved'
        state.currentUser = action.payload;
        state.servings = action.payload.settings.sumFamily
        state.loggedIn = true
      },
      [getUser.rejected]: (state, action)=> {
        state.statusUser = 'rejected'
        state.currentUser = {};
        state.loggedIn = false 
        state.errorUser = action.error.message;
      },
      [updateUser.pending]: (state) => {
        state.statusUser = 'loading';
        state.errorUser = null;
      },
      [updateUser.fulfilled]: (state, action)=> {
        state.statusUser = 'resolved'
        state.currentUser = action.payload;
        state.servings = action.payload.settings.sumFamily;
        state.statusTextUser = 'Данные успешно обновлены' 
      },
      [getRecipeOwnerData.pending]: (state) => {
        state.statusOwner = 'loading';
        state.error = null;
      },
      [getRecipeOwnerData.fulfilled]: (state, action)=> {
        state.statusOwner = 'resolved'; 
        state.recipeOwner = action.payload;
      },
      [getRecipeOwnerData.rejected]: (state, action)=> { 
        state.statusOwner = false;
        state.recipeOwner = false; 
      },
    }
  })

  export const { 
    changeLike
    } = reguestGetSlice.actions;

  export const { addProduct } = reguestProductSlice.actions
  export const { searchRecipe, getRandomRecipe , addFilterRecipes, searchFilterRecipes, checkFilterRecipes, searchWord} = reguestGetSlice.actions;
  export const { infoDelete } = currentUserSlice.actions 