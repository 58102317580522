//Поисковая строка для поиска рецептов по ингредиентам

import React from 'react';
import './Search.css'; 
import { AutoComplete } from '../../../utils/utils.js'

export function SearchByIngredient(props) {
  /*//слушатель на покаидание поля
  function handleBlur(e) {
    setKeyword('');
  }*/
 


  return ( 
    <label className='search__label'>
      
      <input 
        type="search" 
        cols='50' 
        value={props.keywords || ''} 
        onChange={props.handleChangeInput} 
        placeholder='Начните вводить ингредиент' 
        className={`search__input font16Norm`}
        name={props.name}
        id={props.name}
      /> 
      {props.choiceIngredient.length > 0 && 
         <span className='form-settings__list search__list'>
          {props.choiceIngredient.map((i, index)=> (
            <li key={index} className='ingredient__items'><p className='ingredient__search font20Norm'>{i.name}</p> 
              <p onClick={()=> props.handleDelete(i._id)} className='gg-cross m-size' />
            </li>
          )) }
          </span>
        }
     
      <span className='add-recipe__autocomplete'>
        {props.filtrMassiv && props.hidden &&
          props.filtrMassiv.map((item, index) => (
            <AutoComplete 
              key={index} 
              item={item} 
              onClick={()=>props.handleAutoText(item)} 
              name={props.name}
            />    
          ))
        }
      </span>
     
    </label>
  ) 
}
