import React from 'react';
import { AddImages } from "../index.js";
import './AddRecipe.css';
import { Ingredient, TypeFood,StepText, CheckBox, InputArea, InputItem, AddButton, InputCounter, InputTime, InputCheckbox } from '../../atoms/index.js';
import { AddIngredient } from '../index.js';
import { Selection }  from '../index.js';
import { useSelector } from 'react-redux'

export function FormRecipe (props) {
  const { lib, statusLib } = useSelector(state => state.lib) 
  return (
    <>
      <form className='form' onSubmit={props.onSubmit} autoComplete="off">
        <fieldset className='form__fieldset'> 
          <InputItem 
            type='text' 
            types='title' 
            value={props.title}
            onChange={props.onChange}
            placeholder= 'Название блюда'
            name='title' 
            id='title' 
            nameError={props.titleError}
          />
          <Selection
            name='category'
            value={props.category}
            onChange={props.onChange}
            title='Категория блюда'
            nameError={props.category}
            array={statusLib === 'resolved' && lib.dish}
          />
        </fieldset>
        <fieldset className='form__fieldset form__fieldset_quantity'> 
          <InputCounter
            handleCountClickMinus={props.handleCountClickMinus}
            handleCountClickPlus={props.handleCountClickPlus}
            disabled={props.isDisabled}
            name='capacity'
            onChange={props.onChange}
            value={props.capacity}
            title='Количество порций'
          />
            <InputTime 
            onChange={props.onChange}
            hours={props.hours}
            minute={props.minute}
            types=''
            />
            <InputItem 
            type='number' 
            value={props.activeCooking}
            onChange={props.onChange}
            placeholder= ''
            name='activeCooking' 
            id='activeCooking'
            types= 'activeCooking'
            nameru='Активное время, мин'
          /> 
            <InputItem 
            type='number' 
            value={props.minPortion}
            onChange={props.onChange}
            placeholder= ''
            name='minPortion' 
            id='minPortion'
            types= 'minPortion'
            nameru='Минимальная порция'
          />
          </fieldset>
          <fieldset className='form__fieldset'>
            <CheckBox 
              onChange={props.onChange}
              heat={props.heat}
              fresh={props.fresh}
              frying={props.frying}
              thermo={props.thermo}
            />
          </fieldset>
          <fieldset className='form__fieldset'>
            <label className='product-item__label'>
              <input 
                type="checkbox" 
                checked={props.feast}
                onChange={props.onChange}
                className='product-item__choice' 
                name="feast" 
                id="feast"
              />
              <span className="checkbox-green item__link"></span>
              <legend className=''>Праздничное блюдо</legend>
            </label>
           
          </fieldset>
        <fieldset className='form__fieldset form__fieldset_column'>
            <h2 className='form__title font26Bold'>Добавить ингредиенты</h2>
            <div className='add-recipe__ingredients'>
              {props.ingredientsData.length > 0 && //список добавленных ингридиентов
                props.ingredientsData.map((item, index)=> (
                  <Ingredient 
                  key={index} 
                  item={item} 
                  editChange={props.editChange}
                  onClick={props.handleDelete} 
                  filtrMassiv={props.filtrMassiv} 
                  handleChange={props.onChange}
                  onChange={props.onChange}
                  handleAutoText={props.handleAutoText}
                  hidden={props.hidden}
                  ingredient={props.ingredient}
                  measure={props.measure}
                  weight={props.weight} 
              / >
                ))
              }
            </div>
            <AddIngredient //Добавление нового ингредиента
              filtrMassiv={props.filtrMassiv} 
              handleChangeInput={props.handleChangeInput}
              handleChangeInputWeight={props.handleChangeInputWeight}
              handleChangeMeasure={props.handleChangeMeasure}
              selectMeasure={props.selectMeasure}
              handleAutoText={props.handleAutoText}
              onChange={props.onChange}
              hidden={props.hidden}
              onFocus={props.handleFocus}
              thermo={props.thermo}
              thermoself={props.thermoself}
              choiceIngredient={props.choiceIngredient}
              value={props.ingredient}
              ingredientsData={props.ingredientsData}
              name='ingredients'
              measure={props.measure}
              heat={props.heat}
              fresh={props.fresh}
              frying={props.frying} 
              weight={props.weight}
              ingError={props.ingError}
              weightError={props.weightError}
              //name={name}
              />
              <div className='form__block_row '>
                <AddButton 
                  type='button' 
                  onClick={props.handleAddIng}
                  title = 'Добавить ингредиент'
                  submitDisabled={props.addIngDisabled} 
                />
              </div>
            </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'>
            <h2 className='form__title font26Bold'>Выберите тип питания</h2>
              <div className='form__block_row form__block_row_wrap'>
              {props.types.length !== 0 &&
                props.types.map((item)=> (
                <TypeFood 
                item={item} 
                key={item.nameEn} 
                types={props.types} 
                name={item.nameRu}
                id={item.nameEn} 
                onClick={props.handleCheckBox}/>
              ))}
             </div>
          </fieldset>
          <fieldset className='form__fieldset form__fieldset_column'>
          <h2 className='form__title font26Bold'>Выберите инвентарь для готовки</h2>
            <div className='form__block_row form__block_row_wrap'>
            {props.kitchenware.length !== 0 &&
              props.kitchenware.map((item)=> (
              <TypeFood 
                item={item} 
                key={item.nameEn}
                types={props.types} 
                name={item.nameRu}
                id={item.nameEn} 
                onClick={props.handleCheckBox}/>
            ))}
           </div>
        </fieldset>
        <fieldset className='form__fieldset form__fieldset_column'>
        <h2 className='form__title font26Bold'>Советы и подготовка продуктов</h2>
          <div className='form__block_row'>
          <InputArea 
            onChange={props.onChange} 
            name='advice' 
            value={props.advice}
            placeholder='Советы по приготовлению(Необязательно)'
            nameError={props.adviceError}
          /> 
          <InputArea 
            onChange={props.onChange} 
            name='preparation' 
            placeholder='Подготовка продуктов(Необязательно)'
            value={props.preparation}
            nameError={props.preparationError}
          /> 
         </div>
      </fieldset>
          <fieldset className="form__fieldset form__fieldset_column">
            <h2 className="form__title font26Bold" htmlFor="desc">Пошаговая инструкция</h2>
            <p>Подробная иструкция поможет правильно приготовить ваш рецепт.</p>
            <div className='form__block'>
            {props.descError && <span className="email-error add-form__item-error">{props.descError}</span>}
            { [...Array(props.step)].map((item, index) =>   
              <StepText 
                key={index} 
                item={index+1} 
                onChange={props.handleChangeDesc} 
                step={props.step} 
                desc={props.desc} 
                onClick={props.handleDeleteStep} 
                name={`step${index+1}`}
                imageError={props.imageError} 
                imageBlob={props.imageDesc} 
                handleDeleteImgDesc ={props.handleDeleteImgDesc}  
              /> 
             )}
            <span className="gg-add-dish form__add-step font24Bold" onClick={props.handleNewStep}> Добавить шаг</span>
            </div>
            <div className='form__block'>
            <h2 className="form__title font26Bold" htmlFor="desc">Введите ссылку на рецепт если автор не вы</h2>
              <InputItem 
               type='url' 
               types='author'
               value={props.author}
               onChange={props.onChange}
               placeholder= 'Ссылка на рецепт'
               name='author' 
               id='author' 
               nameError={props.nameError}
              />
            </div>
          </fieldset>
        <AddImages 
          onClick={props.onClick} 
          imageError={props.imageError} 
          imageBlob={props.imageBlob} 
          handleDeleteHeritageImage={props.handleDeleteHeritageImage} 
        />
        <AddButton 
          type='submit' 
          title={props.head}
          submitDisabled={props.submitDisabled ? true : false} 
          
        />
       
        {
          props.title === undefined
          ? <span className="add-form__item-error left-error">Добавьте название блюда</span>
          : props.category === undefined
          ? <span className="add-form__item-error left-error">Выберите категорию блюда</span>
          : (props.hours === undefined && props.minute === undefined) ?
          <span className="add-form__item-error left-error">Заполните время приготовления?</span>
          : props.activeCooking === undefined || props.activeCooking === 0 
          ? <span className="add-form__item-error left-error"> Заполните активное время готовки</span> 
          : !props.typesDisabled 
          ? <span className="add-form__item-error left-error">Выберите тип питания</span>
          : props.ingredientsData.length === 0 
          ? <span className="add-form__item-error left-error">Добавьте ингредиенты</span>
          : props.thermo === undefined
          ? <span className="add-form__item-error left-error">Выберите термообработку блюда</span>
          : props.desc.length ===0
           && <span className="add-form__item-error left-error">Заполните шаги приготовления</span>
        }
       
      </form>  
    </>
)
}