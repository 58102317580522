function ModalUpdateForm(props) {

  function handleClick(event) {
    props.onClick (props.cards)
  }
  
  return (
    <div className={`modal modal_type_${props.name} ${props.isOpen ? ('modal_opened') : ''}`} >
      <div className={`modal__container modal__container__type_${props.name}`}>
      <button onClick={props.onClose} type="button" className="modal__close" aria-label="Закрыть форму"></button>
        <h2 className="modal__title modal__title_modal-info">{props.title}</h2>
        <form name={props.name} className="modal__form"  onSubmit ={props.onSubmit} >
          {props.children}
          <button onClick={handleClick} className="modal__button modal__button_active item__link" type="button" aria-label={props.buttonName}>{props.buttonName}</button>
        </form>
      </div>
    </div>
  )
}

export default ModalUpdateForm;