import React from 'react'; 

export function EditCooking(props) {
  const item = props.item 
  function handleChange(e) {
    props.editChange(e.target.value, item._id) 
  }

  //console.log(item)
   return (
    <div className='product__cooking'>
      <label className='product-item__label font20Norm'>
        <input 
          type="radio" 
          value='fresh'  
          checked={item.thermoself ==='fresh' || ''}
          onChange={handleChange}
          className='product-item__choice' 
          name={`${item._id}editthermo`}
          id={`${item._id}fresh`} 
        /> свежий
        <span className="checkbox-green item__link"></span>
      </label>
      <label className='product-item__label font20Norm'>
        <input 
          type="radio" 
          value='heat' 
          checked={item.thermoself ==='heat' || ''}
          onChange={handleChange}
          className='product-item__choice' 
          name={`${item._id}editthermo`}
          id={`${item._id}heat`}
        /> варка
        <span className="checkbox-green item__link"></span>
      </label>
      <label className='product-item__label font20Norm'>
        <input 
          type="radio" 
          value='frying'  
          checked={item.thermoself ==='frying'  || ''}
          onChange={handleChange}
          name={`${item._id}editthermo`}
          className='product-item__choice' 
          id={`${item._id}frying`} 
        />жарка
        <span className="checkbox-green item__link"></span>
      </label>
   </div> 

)
   
}
