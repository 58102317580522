//Верификация пользователя (Подтверждение email)

import React from 'react';
 
const InfoReset = (props) => {
  
  return (
     <h1>
         Ссылка для сброса пароля отправлена на ваш электронный адрес. Если письмо не приходит в течении 5 минут, проверьте папку Спам.
     </h1>
  )
}
export default InfoReset;