//Кнопки выбора типов питания

import React from 'react';
import {popupMessage} from '../../../constants/constants.js'
import { InfoPopup } from '../index.js'  
  
export function TypeFood (props) {
  const item = props.item
  const types = {...item}
  const [values, setValues] = React.useState()
  const [isOpen, setIsOpen] = React.useState(false);
   
  React.useEffect(() => {
    setValues(item.boolean)
  }, [item]);

  function handleClick () {
    setValues(!values)
    types.boolean =!values
    props.onClick(types)
  } 
 
  
  return (
    <div className='diet__block'>
    <li onClick={handleClick} className={`button__type menuweek-button font20Norm ${values && ('button__type_active color')}`}>{item.nameRu}</li>
    <InfoPopup
      isOpen={props.isOpen}  
      isHovering={isOpen}
   >
     <h3 className='popup__title'>{item.nameRu}</h3>
     <p className='popup__text font16Norm'> {popupMessage[item.nameRu]}</p>
   </InfoPopup> 
   </div>
    )
}