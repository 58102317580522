
// Разметка меню 1 дня
import React, { memo } from 'react';  
import { InfoPopup, AddMeals } from '../../atoms/index.js'
import './TemplateMenu.css'; 
import { useSelector} from 'react-redux' 
import { Link } from 'react-router-dom'

export const TemplateMenu = memo((props) => { 
  const item = props.item
  const {ingredients, status} = useSelector(state => state.ingredients)
  const {recipes} = useSelector(state => state.recipes)   
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes)
  const {statusPlan} = useSelector(state => state.plans)
  const [click, setClick] = React.useState(false) // Отслеживание клика для вывода прелоудера в меню
  const [isOpen, setIsOpen] = React.useState(false);
  const myRef = React.useRef()
  const d = new Date();
  const n = d.getDay(); 
  const today = new Date();   
  const { date, breakfast, lunch, supper, dinner, snack, lateDinner } = item
  const blockedSaveMenu = date < today.setHours(0,0,0,0)
  const days = [
    'Вс',
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб'
  ];
  const newarr = [
    {
      arr: breakfast,
      mealsRu: 'Завтрак',
      mealsEn: 'breakfast',
    }, 
    {
      arr: dinner,
      mealsRu: 'Обед',
      mealsEn: 'dinner',
    }, 
    {
      arr: supper,
      mealsRu: 'Ужин',
      mealsEn: 'supper',
    }, 
  ]
  const zeroMeals = newarr.filter(i=> i.arr.length === 0) //Отсутствующие приемы пищи за день
  let [cal, setCal] = React.useState(0)
  let [price, setPrice] = React.useState(0) 
 
  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (isOpen && myRef.current && !myRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [isOpen])

  React.useEffect(()=> {
    if(statusPlan === 'resolved') {
      setClick(false)
    }
  }, [statusPlan])
 
  
  React.useEffect(() => { 
    if(status === 'resolved' && statusPlan ==='resolved' && statusMenu === 'resolved') { 
      let {cal, price} = countSumCalories()
      setCal(cal)
      setPrice(price)
    }
  }, [item, statusMenu]) 
  
  //Расчет итоговых калорий за день 
  function countSumCalories() {
    let cal= 0
    let price= 0
    const dex = {...item}  
    Object.values(dex).map((d) => { 
      if(Array.isArray(d) && d.length> 0) {  
        const fin = d.filter(i=>!i.weight)
        fin.map(item => {
          let showRecipe = recipeDataMenu.find(r => r._id === item)
          if(showRecipe !== undefined) {
            let result = showRecipe.ingredients.reduce(function(acc,v, index){
            const ingredient = ingredients.find(f => f._id === v._id);
            if(ingredient  !== undefined) {
              const totalWeights = ingredient.measure.find(i=> i.nameRu === v.choisMeasure)
              if(totalWeights) {
                const totalWeight = totalWeights.ratio*v.weight;
                acc.calories += Number(ingredient.calories.calories*totalWeight/100)
              if(ingredient.price) {
                if(item.choisMeasure === ingredient.mainMeasure && ingredient.price.measureprice === ingredient.mainMeasure) {
                  const price = (ingredient.price.rate/ingredient.price.weightprice)*item.weight
                  acc.price += Number(price)
                } else { 
                  //перевести цену за основной вес
                  const rightPrice = ingredient.measure.find(i=> i.nameRu === ingredient.price.measureprice).ratio
                  const price = (ingredient.price.rate/(ingredient.price.weightprice*rightPrice)) * totalWeight
                  acc.price += Number(price)
                } 
              }
            }
            }
              return acc;
          }, { calories:0, price: 0});
         
          cal += result.calories/showRecipe.servings
          price += result.price/showRecipe.servings
        }
         })
        }
      })
      
    return {cal, price} 
  }

                              /* Действия с планом на день*/ 
  //функция удаления меню с определенного дня
  function handleDeleteMenuDay() {
    if(!blockedSaveMenu) {
      props.handleDeleteMenuDay(item._id)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    }  
  }

  // Автозамена плана на день
  function handleAutoReplaceMenu() { 
    if(!blockedSaveMenu) {
      props.handleAutoReplaceMenu(item)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  } 
  
  // Добавления дополнительного приема пищи в план
  function handleOpenAddMeals() { 
    setIsOpen(!isOpen) 
  }

  //Добавление приема пищи в план
  function handleAddMeals(meals) {
      console.log(meals, item)
    props.handleAddMeals(meals, item) 
    setIsOpen(!isOpen)
  } 

                                 /* Действия с планом на каждый прием пищи*/ 
 
  //функция добавления блюда в план
  function handleAddMenuItem(mealsRu, mealsEn) {
    if(!blockedSaveMenu) {
      props.handleAddMenuItem(mealsRu, mealsEn, item)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }
  //функция удаления блюда из плана
    function handleDeleteRecipe(id, powerMode) {
      props.handleDeleteRecipe(id, powerMode, item)
    }
  // Автозамена блюда в плане, передает данные в модалку для замены рецепта
  function handleAutoReplace(recipe, mealsEn, mealsRu) { 
    props.handleAutoReplace(recipe, mealsEn, mealsRu, item)
  }
  
  //функция замены блюда из плана
  function handleEdite(id, mealsEn, mealsRu) {
    props.handleEdite(id, mealsEn, mealsRu, item)
  }
  
  return ( 
    <div className='menu-item template-item'>
      <div className='menu-item__date font20Norm'>
        <p className='menu-item__weekday'>{item ? days[n] : ''}</p>
        <p className='menu-item__day'>{item ? d.toLocaleDateString() : ''}</p>
        <button disabled className='gg-replaceDay x-size' onClick={handleAutoReplaceMenu}/>
        <button className='gg-add x-size' onClick={handleOpenAddMeals}></button>
        <button disabled className='gg-delete-white x-size' onClick={handleDeleteMenuDay}/> 
        <InfoPopup 
          isOpen={isOpen}
          ref={myRef}  
          type='meal'
        >
         {zeroMeals.map((item, index)=> (
        <AddMeals 
          item={item} 
          onClick={handleAddMeals} 
          key={item.mealsEn} />
      ))}
         
      </InfoPopup> 
      </div>
      {
        newarr.map((i, index)=> (
          <MenuMeals 
            item={i} 
            key= {index}
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe = {handleDeleteRecipe}
            handleEdite = {handleEdite}
            autoReplace={handleAutoReplace}
            handleAddDish={handleAddMenuItem}
            click={click} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus} 
          />
        ))
      }
      <div className='menu-item__bottom template__block'>
        <p className='menu-item__cal'>{Math.ceil(cal)} Ккал</p>
        <p className='menu-item__price'> {Math.ceil(price)} &#8381;</p>
      </div>
      
    
     </div>
  )  
})


function MenuMeals(props) {
    const {arr, mealsRu, mealsEn} = props.item 
    return (
     
      <article className='menu-meals'>
        <div className='menu-meals__heading'>
          <div className="menu-meals__title"><span className='font16N'>{mealsRu}</span></div> 
          <button onClick={()=> props.handleAddDish(mealsRu, mealsEn)} className='gg-add-dish m-size'/>
        </div>
          {arr.length > 0 ?
            arr.map((i, index)=> (
            <MenuDish 
              item={i} 
              mealsRu={mealsRu} 
              mealsEn={mealsEn}
              key={index}
              hoax={props.hoax}
              setHoax={props.setHoax}
              handleDeleteRecipe={props.handleDeleteRecipe}
              handleEdite={props.handleEdite}
              autoReplace={props.autoReplace}
              click={props.click} 
              clickCreate={props.clickCreate}
              blockedSaveMenu={props.blockedSaveMenu}
              blockedSaveMenus={props.blockedSaveMenus}
              countPlan={props.countPlan}
            />
          ))
        : 
        [...Array(1)].map((i, index) =>  (
            <MenuDish 
            item={i} 
            mealsRu={mealsRu} 
            mealsEn={mealsEn}
            key={index}
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe={props.handleDeleteRecipe}
            handleEdite={props.handleEdite}
            autoReplace={props.autoReplace}
            click={props.click} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={props.blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus}
            countPlan={props.countPlan}
          />
        ))
        }
      </article>
    
    )  
  }



  function MenuDish (props) {
    const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
    const {statusPlan} = useSelector(state => state.plans) 
    let id= props.item  
    let recipe = recipeDataMenu.find(i=> i._id === id)
    const [click, setClick] = React.useState(false) //Отслеживание клика для вывода прелоудера в меню
    const [isHovering, setIsHovering] = React.useState(false);
    console.log(id, recipe)
    React.useEffect(()=> {
      if(statusPlan === 'resolved' ) {
        setClick(false)
    
      }
      if(props.hoax ) {
        setClick(false)
        props.setHoax(false)
      }
      setIsHovering(false)
    }, [statusPlan, props.hoax]) 
    
    function handleReplace() {
      if(!props.blockedSaveMenu) {
        setClick(true)
        props.autoReplace(recipe, props.mealsEn, props.mealsRu)
      } else {
        props.blockedSaveMenus('blockedUpdateMenu')
      } 
    }
  
    function handleEdite() {
      if(!props.blockedSaveMenu) {
        setClick(true)
        props.handleEdite(id, props.mealsEn, props.mealsRu)
      } else {
        props.blockedSaveMenus('blockedUpdateMenu')
      } 
    }
  
    function handleDeleteRecipe() {
      if(!props.blockedSaveMenu) {
        setClick(true)
        props.handleDeleteRecipe(id, props.mealsEn)
      } else {
        props.blockedSaveMenus('blockedUpdateMenu')
      } 
    }
  
    function handleClickAttention() {
      setIsHovering(!isHovering)
    }
   
    return (
      <div className='menu-dish'>
        {id === undefined
          ?  <div className='menu-dish__image dish-sceleton_img'> </div>
          :  <img  className='menu-dish__image' src={recipe && recipe.image[0] }/> 
        }
        <div className='menu-dish__info'> 
        {id === undefined
          ?  <div className='menu-dish__icons_rows'> </div>
          :  <> 
          <div className='menu-dish__heading'>
          <Link className='menu-dish__title font16N' target="_blank"  to={`/recipes/${id}`}>{recipe && recipe.name}</Link>
          {recipe.preparation !=='' &&
            <button type='button' onClick={handleClickAttention} className='preparation__icon m-size'>&#42;
              <InfoPopup
                isOpen={isHovering}
                type='preparation'
              >
             <p className='popup__preparation'>Подготовка продуктов:</p> <span className="add-form__item-error left-error">{recipe.preparation}  </span>
             </InfoPopup> 
           </button>}
          </div>
          <div className='menu-dish__icons_rows'>
            <button  className='gg-replace m-size' onClick={handleReplace}/>
            <button onClick={handleEdite} className='gg-edit m-size'/>
            <p className='menu-dish__cal'>{Math.ceil(recipe && recipe.calories.calories/recipe.servings)} ккал</p>
          </div>
          </>
        }
        
        </div>
        <button onClick={handleDeleteRecipe} className='menu-dish__delete gg-delete m-size' />
      </div>
    )
  }