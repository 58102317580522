//Поисковая строка

import React from 'react';
import './Search.css';

export function Search(props) {
  const [keyword, setKeyword] = React.useState('')
  //Слушатель на ввод поисковых слов
  function handleChangeInput(e) {
    let temp = e.target.value.split(",").map((item)=>item.trim());
    setKeyword(e.target.value);
    props.onSearch(temp)
  }

  /*//слушатель на покаидание поля
  function handleBlur(e) {
    setKeyword('');
  }*/

  return ( 
      <label className="search__label font16Norm">
        <input 
          className="search__input" 
          value={props.keyword || ''} 
          //onBlur={handleBlur} 
          onChange={props.onSearch} 
          name="search" 
          placeholder={props.placeholder} 
          type="search" 
          id="search" 
        />  
    
      </label>
   
  ) 
}
