
export function ModalChoiceAsk(props) {
  
    function handleClick(event) {
      props.onClick (props.cards)
    }
    
    return (
      <div className={`modal modal_type_${props.name} ${props.isOpen ? ('modal_opened') : ''}`} >
        <div className={`modal__container modal__container__type_${props.name}`}>
        <button onClick={props.onClose} type="button" className="modal__close" aria-label="Закрыть форму"></button>
          <h2 className="modal__title modal__title_modal-info">Вы уверены что хотите удалить рецепт: <span className='modal_recipe-name color'>{ props.cards.name}</span></h2>
          <div name={props.name} className="row"  onSubmit ={props.onSubmit} > 
            <button onClick={handleClick} className="menuweek-button " type="button" aria-label={props.buttonName}>Да</button>
            <button onClick={props.onClose} className="menuweek-button color  " type="button" aria-label={props.buttonName}>Нет</button>
          </div>
        </div>
      </div>
    )
  } 