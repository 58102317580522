//Личный кабинет(Профиль)

import React from 'react';
import './Profile.css'; 
import { Outlet } from 'react-router-dom'

export function Profile(props) {
  
  return (
    <>
      <Outlet />
    </>
  ) 
}
