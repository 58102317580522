import * as React from 'react';
import { render } from 'react-dom';
import { SimpleCalendar } from './SimpleCalendar.js';
import styled from 'styled-components';
import './styles.css';


export const Calendars = React.forwardRef((props, ref) => { 
  const today = new Date().toLocaleDateString()
  
  return (
    <section className='calendar '>
       <button 
          type='button'  
          className={`menuweek-button button__gener calendar__today font20Norm color`} 
          onClick={props.handleCalendarVisibility}
        > Выберите даты
        </button>
 
      <SimpleCalendar 
        ref={ref}
        dateDay={props.dateDay} 
        userMenu={props.userMenu} 
        onDayRange={props.onDayRange} 
        isOpen={props.isOpen}
        getLocaldata={props.getLocaldata}
      /> 
    </section>
  );
})
