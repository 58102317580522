// Страница о сервисе

import React from 'react';
import './Home.css';
import {useSelector } from 'react-redux'
import {Promo, MainVideo } from '../../molecules/index.js'
import { SloganSlider, Manual, Types, Leading } from '../../organisms/index.js'
//import { Helmet } from 'react-helmet-async';

export function Home() {
 
  return (
    <>
    {/*<Helmet>
      <title>Конструктор меню онлайн. Составить план питания бесплатно, список покупок, КБЖУ меню.</title>
      <meta name="description" content='План питания по индивидуальным настройкам и калорийности, получи список покупок, КБЖУ рецепта и плана на день. Столы 1 2, Кето рецепты, Детское меню.'/> 
    </Helmet>*/}
      <Promo />
      <SloganSlider />
      <Types />
      <Leading />
      <Manual /> 
   
    </>
  )  
}
