//Инструкция

import React from 'react';
import './Leading.css';
import traditional from '../../../images/traditional-type.jpg';
import vegan from '../../../images/vegan.jpg';
import keto from '../../../images/keto.jpeg';
import table from '../../../images/kartin.jpg';
 
export function Leading (Types) {
  
  return (
    <section className='leading'>
      <h2 className='font26Bold'>Наш сервис Вам подходит, если:</h2>
      {/*<img src ={table} className='leading__img'/>*/}
      <div className='leading__block'>
        <p className='leading__title font24Norm'>Вы устали думать что приготовить</p>
        <p className='leading__title font24Norm'>При закупке продуктов забываете что-то купить</p>
        <p className='leading__title font24Norm'>Вы на диете и в поиске рецептов, чтобы  разнообразить свой рацион</p>
        <p className='leading__title font24Norm'>Часто выбрасываете продукты, которые не успели приготовить</p>
      </div> 
     
    </section>
)
   
}