//Шапка сайта

import React from 'react';
import {Link, NavLink, } from 'react-router-dom'
import './Header.css';
import { Sidebar } from '../index.js'
import { CurrentUserContext } from "../../../contexts/CurrentUserContext.js";
import { ProfileItem } from '../../atoms/index.js';
import { profileCategory } from '../../../constants/constants.js';
 
export function Header(props) { 
  const myRef = React.useRef()
  const currentUser = React.useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [showSideMenu, setShowSideMenu] = React.useState(false)
  const [showSideBar, setShowSideBar] = React.useState(false)
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  function checkWindowWidth() {
    setTimeout(() => setWindowWidth(window.innerWidth), 500);
  };
  React.useEffect(()=> {
    window.addEventListener('resize', checkWindowWidth)
    if(window.innerWidth > 521) {
      setShowSideBar(false) 
      } else {
        setShowSideBar(true) 
      }  
      return () => window.removeEventListener('resize', checkWindowWidth);
  
  }, [windowWidth])
  
  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (isOpen && myRef.current && !myRef.current.contains(e.target) && !e.target.classList.contains('header__link_type_profile')) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [isOpen])

  React.useEffect(() => {
    if(showSideMenu) {
      document.body.classList.add('hidden'); 
    } else {
      document.body.classList.remove('hidden');
    } 
  }, [showSideMenu]);


  //Изменение стейта клика на профиль
  function handleClickInfo() { 
    setIsOpen(!isOpen)
  }

  function handleSignOut() {
    setIsOpen(false)
    props.onSignOut()
  } 

  function handleBurgerClick() {
    setShowSideMenu(!showSideMenu)
  } 

  return (
    <header className={`header color ${props.scrollTop> 0 ? 'header__sticky': ''}`}>
       <button className='gg-burger' type='button' onClick={handleBurgerClick} aria-label='навигация'/>
      <div className='header__logo'>
        <Link to='/' className="header__brand">MenuBook</Link>
        <span className='header__slogan font18Bold'>Планируй и&nbsp;готовь легко</span>
      </div>
      <nav className='lk-menu'>
        <ul className="lk-menu__list">
          <li className="lk-menu__link"><NavLink to='/recipes' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : 'lk-menu__text'}`}>Рецепты</NavLink></li>
          <li className="lk-menu__link"><NavLink to='/feast' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text"}`} >Новогоднее меню</NavLink></li>
          <li className="lk-menu__link"><NavLink to='/user/my-menu' className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text"}`} >Составить меню</NavLink></li>
        </ul>
      </nav> 
      <div className={`modal modal_type_${props.name} ${showSideMenu ? ('modal_opened') : ''}`} >
        <nav className={`side-menu side-menu__left ${showSideMenu ? 'side-menu__show' : ''}`} >
          <button className='gg-closed' onClick={handleBurgerClick} aria-label='меню пользователя' />
          <ul className="side__list">
            <li className="lk-menu__link"><NavLink to='/recipes' onClick={handleBurgerClick} className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text font26Norm"}`} >Рецепты</NavLink></li>
            <li className="lk-menu__link"><NavLink to='/feast' onClick={handleBurgerClick} className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text font26Norm"}`}>Новогоднее меню</NavLink></li>
            <li className="lk-menu__link"><NavLink to='/user/my-menu' onClick={handleBurgerClick} className={({isActive}) => `${isActive ? "lk-menu__text lk-menu__text_active" : "lk-menu__text font26Norm"}`} >Составить меню</NavLink></li>
          </ul>
        </nav> 
      </div> 
      {props.loggedIn && !showSideBar
        ?  
        <div className='header__info'>
          <p className='header__name font20Norm'>{currentUser.login ? currentUser.login : currentUser.name}</p>
          <button onClick={handleClickInfo} className="header__link_type_profile item__link" />
          <Sidebar 
            isOpen={isOpen}  
            type='nav'
            loggedIn={props.loggedIn}  
            onSignOut={handleSignOut} 
            ref={myRef} 
            handleClickMenu={handleClickInfo}
          />
        </div> 
        : props.loggedIn && showSideBar 
        ?
        <div className='header__info'>
        <p className='header__name font20Norm'>{currentUser.login ? currentUser.login : currentUser.name}</p>
        <button onClick={handleClickInfo} className="header__link_type_profile item__link" />
        <div className={`modal modal_type_${props.name} ${isOpen ? ('modal_opened') : ''}`} >
        <nav className={`side-menu side-menu__left ${isOpen ? 'side-menu__show' : ''}`} >
          <button className='gg-closed' onClick={handleClickInfo} />
          <div className='side__list'>
          {profileCategory.map((item)=> (
            <ProfileItem item={item} onClick={handleClickInfo} onSignOut={props.onSignOut} key={item.id} />
          ))}
           </div> 
        </nav> 
      </div> 
      </div> 
        :
        <div className='header__info'>
          <Link to="/signin" aria-label="Выход из системы" className="header__link_type_profile" />  
        </div> 
      } 

        

    </header>
  )   
}
