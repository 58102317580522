//Разметка добавления данных

import React from 'react';

export function InputTime (props) {
   
  return (
    <div className='form__block form__block_time' >
      <legend className='form__title font20Bold'>Время приготовления</legend>
      <label className='form__label form__label_type_row' htmlFor='time'>
        <input 
          type='number' 
          value={props.hours || ''} 
          onChange={props.onChange} 
          min="0" 
          max="10" 
          className='add-recipe__input add-recipe__input_time'
          name='hours' 
          id='hours'
        />
        <span className='add-recipe__span'>часа(ов)</span>
        <input 
          type='number' 
          value={props.minute || ''} 
          onChange={props.onChange} 
          min="0"
          max="59" 
          className='add-recipe__input add-recipe__input_time' 
          name='minute' 
          id='minute'
        />
        <span>минут</span>
      </label>
      {(props.hours ==='' && props.minute ==='') && <span className="add-form__item-error">Поле не может быть пустым</span>}
    </div>
  )
}