import { createSlice } from '@reduxjs/toolkit';

export const IngredientsSlise = createSlice({
    name: 'ingredientsData',
    initialState: {
      ingredientsData: [],
      ingredientsObj: {},
    },
    reducers: {
      addIngredients(state, action) {
        state.ingredientsData.push(action.payload)
      },
      removeIngredients(state, action) {
        state.ingredientsData = state.ingredientsData.filter(ingredientsData => ingredientsData._id !== action.payload);
      },
      editIngredients(state, action) {
         state.ingredientsData = state.ingredientsData.map((c) => c._id === action.payload.id ? action.payload.choiceIngredient : c)
      },
      addingredientsObj(state, action) {
        state.ingredientsObj.push(action.payload)
      },
      editIngredientsData(state, action) {
        state.ingredientsData = action.payload
      },
      deleteAllIngredients(state, action) {
        state.ingredientsData = []
      },
    }
    
  })

  export const {addIngredients, editIngredientsData, removeIngredients, editIngredients, addingredientsObj, deleteAllIngredients} = IngredientsSlise.actions;

