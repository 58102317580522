//Инпуты с возможностью множественного выбора(квадратный)
import React from 'react';
 
export function InputCheckbox (props) {
  const item = {...props.item}
  const [checked, setChecked] = React.useState(item.boolean);

  React.useMemo(()=> { 
    setChecked(item.boolean)
  }, [item.boolean])
  
  //Выбор ингредиента который есть в наличии
  function handleChange(e) {
    setChecked(!checked)
    item.boolean = !checked
    props.onChange(item)
 } 
 
  return ( 
      <label className='checkbox__label font20Norm'>
        <input 
          type="checkbox" 
          checked={checked}
          disabled={props.fetching === 'loading' ? true : ''}
          onChange={handleChange}
          className='checkbox__input'
          name={props.type} 
          id={item.nameEn} 
        /> 
      <span className={`checkbox-click  ${props.fetching === 'loading'  ? 'checkbox__waiting' : 'item__link'}`}></span>
      {item.nameRu}
      </label>
   
  )
}

