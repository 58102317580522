import './SceletonMenu.css';

export function SceletonSideFilter(props) {

  return (
    <div className='pattern__filter'> 
    <h3 className='col__title font20Bold'>Фильтры </h3>
    { [...Array(10)].map((item, index) =>  
      <div key={index} className='pattern__icon2 flare'></div>
      )}
    </div>

  )
}