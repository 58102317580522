import React from 'react';
import ModalUpdateForm from './ModalUpdateForm.js'
import '../ModalInfo/ModalInfo.css'

export function ModalMenuUpdate(props) {

  return (
    <ModalUpdateForm 
    onClick={props.onClick} 
    name="update-card" 
    title="На эти даты уже есть сохраненные меню. Заменить?" 
    buttonName="Да" 
    isOpen={props.isOpen} 
    onClose={props.onClose}
    cards={props.cards}
    />
  )
}
