//Список продуктов на странице рецепта

import React from 'react';

export function IngredientList (props) { 
  const item = props.item
  const capacity = props.capacity
  const weight = item.choisMeasure === 'по вкусу' || item.choisMeasure === 'щепотка'
  ? '' 
  : (Math.ceil((item.weight/props.servings)*capacity*100)/100) //Расчет веса исходя из количества персон или счетчика
  const ingBase = props.ingredients.find(i=> i._id ===item._id) // находим по id нужный ингредиент*/
  //console.log(ingBase, item, capacity, props.servings)
  
  return (
    <li className='product-item'>
      <div className='product-item__head'>
        <p className='product-item__title font20Norm product-card__title'>{ingBase.name}</p>
        <span className='product-item__title font20Norm product-card__title'> {ingBase.trait}</span>
        <span className='recipe__border'> </span>
      </div>
      <span className='product-item__weight font20Norm'>{weight} </span>
      <span className='product-item__measure font20Norm'>{item.choisMeasure} </span> 
    </li>
  )
}