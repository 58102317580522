import React from 'react';

export function InputItem(props) {
  
  return (
    <div className={`${(props.types === 'minPortion' || props.types === 'activeCooking') ? 'form-input_quantity' : props.types === 'author' ? 'form-input_url' : 'form-input_block'}`}>
      {(props.types === 'minPortion' || props.types === 'activeCooking') &&
        <legend className='font20Bold form__title'> {props.nameru}</legend>
      }
      <label className={`form__label font20Bold form__label_type_${props.types}`} htmlFor={props.name}>
        <input 
          type={props.type}
          autoComplete="off" 
          value={props.value || ''} 
          step='any'
          onChange={props.onChange} 
          placeholder={props.placeholder}
          className = {`form__input font20Norm form__input_type_${props.name}`} 
          name={props.name} 
          id={props.id} 
       />
       {props.nameError && <span className="email-error form__input_error">{props.nameError}</span>}
     </label>
    </div>
  )        
}
  