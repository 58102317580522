import './SceletonMenu.css';
import React from 'react';
export function SceletonMenu(props) {
  const [count, setCount] = React.useState(0)
  const [length, setLength] = React.useState(0)
 /*
  React.useEffect(()=> {
    if(window.innerWidth > 1024) {
      if(props.count >3) {
        setCount(3) 
      } else {
        setCount(props.count)
      }
      setLength(4)
      } else if(window.innerWidth > 768 && window.innerWidth <= 1024) {
        if(props.count >2) {
          setCount(2) 
        } else {
          setCount(props.count)
        }
        setLength(3)
      } else if(window.innerWidth <= 768) {
        setCount(1)
        setLength(3)
      } 
  }, [])*/

return (
  <div className='sceleton-menu'>
    { [...Array(count)].map((item, index) =>  
      <div key={index} className='pattern'>
        <div className='menu-item__date font20Norm'></div>
        { [...Array(length)].map((item, i) => 
          <div key={i}className='pattern__block'> 
            <div className='pattern__image flare'> </div>
            <div className='pattern__info'> 
              <div className='pattern__icon2 flare'></div>
              <div className='pattern__icon3 flare'></div>
              <div className='pattern__icon2 flare'></div>
            </div>
          </div> 
        )}
       
      </div> 
    )}
   
  </div>
)}