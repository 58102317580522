
// Разметка меню для ручного редактирования
import React, { memo } from 'react'; 
import { MenuMeals } from '../../molecules/index.js'
import { InfoPopup, AddMeals } from '../../atoms/index.js' 
import { useSelector} from 'react-redux' 

export const MenuSelf = memo((props) => { 
  const item = props.feastPlan
  const {ingredients, status} = useSelector(state => state.ingredients)
  const {recipes} = useSelector(state => state.recipes)   
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes)
  const {statusPlan} = useSelector(state => state.plans)
  const [click, setClick] = React.useState(false) // Отслеживание клика для вывода прелоудера в меню
  const [isOpen, setIsOpen] = React.useState(false);
  const myRef = React.useRef()
  const { date, Dish, desert, salads, snack} = item
  
  const today = new Date();  
  const d = new Date(today);
  const n = d.getDay(); 
   const blockedSaveMenu = false
  const days = [
    'Вс',
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб'
  ];
  const newarr = [
    {
      arr: Dish,
      mealsRu: 'Основное блюдо',
      mealsEn: 'Dish',
    }, 
    {
      arr: salads,
      mealsRu: 'Салаты',
      mealsEn: 'salads',
    }, 
    {
      arr: snack,
      mealsRu: 'Закуски',
      mealsEn: 'snack',
    },  
    {
      arr: desert,
      mealsRu: 'Десерты',
      mealsEn: 'desert',
    }, 
  ]

  console.log(newarr)
 const zeroMeals = newarr.filter(i=> i.arr.length === 0) //Отсутствующие приемы пищи за день
  let [cal, setCal] = React.useState(0)
  let [price, setPrice] = React.useState(0) 
 
  //Закрытие модалки профиля при клике мышке за предлами модального окна
  React.useEffect(() => {
    const click = e => {
      if (isOpen && myRef.current && !myRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", click)
    return () => {
      document.removeEventListener("mousedown", click)
    }
  }, [isOpen])

  React.useEffect(()=> {
    if(statusPlan === 'resolved') {
      setClick(false)
    }
  }, [statusPlan])
 
   

                              /* Действия с планом на день*/ 
  //функция удаления меню с определенного дня
  function handleDeleteMenuDay() {
    if(!blockedSaveMenu) {
      props.handleDeleteMenuDay(item._id)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    }  
  }

  // Автозамена плана на день
  function handleAutoReplaceMenu() { 
    if(!blockedSaveMenu) {
      props.handleAutoReplaceMenu(item)
      setClick(true)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  } 
  
  // Добавления дополнительного приема пищи в план
  function handleOpenAddMeals() {
    if(!blockedSaveMenu) {
      setIsOpen(!isOpen)
    } else {
      props.blockedSaveMenus('blockedSaveMenu')
    }  
  }

  //Добавление приема пищи в план
  function handleAddMeals(meals) {
    props.handleAddMeals(meals, item) 
    setIsOpen(!isOpen)
  } 

                                 /* Действия с планом на каждый прием пищи*/ 
  //функция добавления блюда в план
  function handleAddMenuItem(mealsRu, mealsEn) {
    if(!blockedSaveMenu) {
      props.handleAddMenuItem(mealsRu, mealsEn, item)
    } else {
      props.blockedSaveMenus('blockedUpdateMenu')
    } 
  }
  //функция удаления блюда из плана
    function handleDeleteRecipe(id, powerMode) {
      props.handleDeleteRecipe(id, powerMode, item)
    }
  // Автозамена блюда в плане, передает данные в модалку для замены рецепта
  function handleAutoReplace(recipe, mealsEn, mealsRu) { 
    props.handleAutoReplace(recipe, mealsEn, mealsRu, item)
  }
  
  //функция замены блюда из плана
  function handleEdite(id, mealsEn, mealsRu) {
    props.handleEdite(id, mealsEn, mealsRu, item)
  }
  
  return ( 
    <div className='menu-item'>
      <div className='menu-item__date font20Norm'>
        <p className='menu-item__weekday'>{item ? days[n] : ''}</p>
        <p className='menu-item__day'>{item ? d.toLocaleDateString() : ''}</p>
        <button className='gg-replaceDay x-size' onClick={handleAutoReplaceMenu}/>
        <button className='gg-add x-size' onClick={handleOpenAddMeals}></button>
        <button className='gg-delete-white x-size' onClick={handleDeleteMenuDay}/>
        <InfoPopup 
          isOpen={isOpen}
          ref={myRef}  
          type='meal'
        >
         {zeroMeals.map((item, index)=> (
        <AddMeals 
          item={item} 
          onClick={handleAddMeals} 
          key={item.mealsEn} />
      ))}
         
      </InfoPopup> 
      </div>
      {
        newarr.map((i, index)=> (
          <MenuMeals 
            item={i} 
            key= {index}
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe = {handleDeleteRecipe}
            handleEdite = {handleEdite}
            autoReplace={handleAutoReplace}
            handleAddDish={handleAddMenuItem}
            click={click} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus} 
          />
        ))
      }
      <div className='menu-item__bottom'>
        <p className='menu-item__cal'>{Math.ceil(cal)} Ккал</p>
        <p className='menu-item__price'> {Math.ceil(price)} &#8381;</p>
      </div>
      
    
     </div>
  )  
})