
export function DishChecking (arr, recipes, ingredients) {
  const food  = {}
  arr.map((v)=> {
    const dex = {...v}
    Object.values(dex).map((d) => { 
      if(Array.isArray(d) && d.length> 0) {  
        const fin = d.filter(i=>i !== undefined && !i.weight) 
        fin.map(item => {
          let showRecipe = recipes.find(r => r._id === item)
          if(showRecipe !== undefined) {
            showRecipe.ingredients.forEach((ingredient) => {
              const ingBase = ingredients.find(c=> String(c._id) === ingredient._id) 
              let key
              let weight
              let choisMeasure
              const crow = ingBase.measure.find(function(j) {
                return j.nameRu.includes(ingredient.choisMeasure);
              });
              if(crow !== undefined) {
                key = `${ingredient._id}_${ingBase.mainMeasure}_${showRecipe.name}`;
                if(ingBase.mainMeasure === ingredient.choisMeasure) { 
                  weight = Number(ingredient.weight) / showRecipe.servings
                } else {
                  weight = (crow.ratio * Number(ingredient.weight)) / showRecipe.servings
                } 
                choisMeasure = ingBase.mainMeasure
              } else {
                key = `${ingredient._id}_${ingredient.choisMeasure}_${showRecipe.name}`;
                weight = Number(ingredient.weight) / showRecipe.servings
                choisMeasure = ingredient.choisMeasure
              }
              if (food[key]) {
                food[key].weight += Number(weight);
              } else {
                food[key] = {
                  id: ingredient._id,
                  weight: Number(weight),
                  choisMeasure: choisMeasure,
                  name: showRecipe.name,
                };
              }  
            });
        }
       })
      }
    })

  }) 
  const combinedIngredients = Object.values(food);
  return combinedIngredients 
}