import React from 'react';
 
export function SloganBlock(props) {
  const item = props.item
  
  return (
    <div className='slogan__block'>
      <div className='slogan__content'>
        <h2 className='slogan__title font35Bold'>{item.title}</h2>
        <p className='slogan__text font20Norm'>{item.subtitile}</p>  
      </div>
      <span className='slogan__border'></span>  
    </div>
  )  
}