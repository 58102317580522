//Инструкция

import React from 'react';
 
export function List (props) {
  const item = props.item
  return (
     
        <li className='manual__list'>  
          <p className='checking'></p>
          <div className='manual__column'>  
           <p className='manual__title font24Bold'>{item.title}</p> 
           <p className='manual__subtitle font18M'>{item.subtitle}</p> 
           </div>  
        </li>
      
)
   
}