// Разметка приема пищи

import React from 'react';
import {MenuDish} from '../../atoms/index.js'

export function MenuMeals(props) {
  const {arr, mealsRu, mealsEn} = props.item
 
  return (
    <>
    {arr.length > 0 &&
    <article className='menu-meals'>
      <div className='menu-meals__heading'>
        <div className="menu-meals__title"><span className='font16N'>{mealsRu}</span></div> 
        <button onClick={()=> props.handleAddDish(mealsRu, mealsEn)} className='gg-add-dish m-size'/>
      </div>
        {arr.map((i, index)=> (
          <MenuDish 
            item={i} 
            mealsRu={mealsRu} 
            mealsEn={mealsEn}
            key={index}
            hoax={props.hoax}
            setHoax={props.setHoax}
            handleDeleteRecipe={props.handleDeleteRecipe}
            handleEdite={props.handleEdite}
            autoReplace={props.autoReplace}
            click={props.click} 
            clickCreate={props.clickCreate}
            blockedSaveMenu={props.blockedSaveMenu}
            blockedSaveMenus={props.blockedSaveMenus}
            countPlan={props.countPlan}
          />
        ))}
    </article>
    }
    </>
  )  
}