//Вывод и удаление фильтров на мобильных экранах(где скрыты боковые фильтры)

import React from 'react';
 
export function FilterRecipe(props) {
  const { type, category } = props 

  function handleDelete(item) { 
    item.boolean = false
    props.handleDelete(item) 
  }

  function handleDeleteCat(item) { 
    item.boolean = false
    props.handleDeleteCat(item) 
  }

  return ( 
      <div className="recipes-filter">
        {type &&
            type.map((t, index)=> (
              <div key={index} className='recipe__filter_item'>
                <p className='filter__item'>{t.nameRu} </p>
                <p onClick={()=>handleDelete(t)} className='gg-cross m-size' >
                </p>
              </div>
            ))
            
        }
        {category &&
          category.map((t, index)=> (
            <div key={index} className='recipe__filter_item'>
              <p className='filter__item'>{t.nameRu} </p>
              <p onClick={()=>handleDeleteCat(t)} className='gg-cross m-size' >
              </p>
            </div>
          ))
        }
    
      </div>
   
  ) 
}
