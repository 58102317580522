import './SceletonMenu.css';

export function SceletonProduct(props) {

  return (
   
    <div className='pattern__product'> 
     { [...Array(20)].map((item, index) => 
      <div key={index} className='pattern__icon2 flare'></div>
      
      )}
    </div>

  )
}