
import React from 'react';
import './UserInfo.css';
import {InfoThemes} from '../../organisms/index.js' 
import {useSelector} from 'react-redux'

export function UserInfo(props) {
  const { currentUser } = useSelector(state => state.currentUser)
  const dateAcces = new Date(currentUser.dateAcces).toLocaleDateString()
  const [hover, setHover] = React.useState(false)
  const [sumTraffick, setSumTraffick] = React.useState([])
  const acces = currentUser.acces
  const disabled = currentUser.bonus.summ < currentUser.subscriptions.price
  let date = new Date(currentUser.dateAcces);
  date.setDate(date.getDate() + 31)
  date.setHours(23, 59, 59, 59);
 
  const traffick = {
    datePayment: new Date(),
    summPayment: currentUser.subscriptions.price, 
  }

  React.useEffect(()=>{
    setSumTraffick([traffick, ...sumTraffick]); 
  }, [])

  //Пополнение счета
  function hanleClickPay() {

  }

  //Продление пописки
  function hanleClickSubs() { 
    const bonus = {
      summ: currentUser.bonus.summ - currentUser.subscriptions.price,
      recipeActive: currentUser.bonus.recipeActive,
      sumTraffick: sumTraffick,
      recipeInActive: currentUser.bonus.recipeInActive,
    };
    const dateAcces = date;
    props.extensionSubs(dateAcces, bonus)
  }


  function hanleBonusText() {
    setHover(!hover)
  }

  return (
    <div className='my-info'>
      <article className='my-info__table'>
        <h2 className='my-info__name font24Norm'>Подписка</h2>
        <p className='my-info__title font26Bold'>{currentUser.subscriptions.title}<span className='gg-ok'></span></p> 
        <p className='my-info__item font24Norm'>До {dateAcces}</p>
        <p className='my-info__item font24Norm'>Сменить</p>
       
      </article>
      <article className='my-info__table'>
        <h2 className='my-info__name font24Norm'>Финансы</h2>
        <p className='my-info__title font26Bold'> {currentUser.finance.summ} &#8381;</p>
        {!acces &&
          <p className='my-info__item_date'>Доступ заблокирован</p>
        }
        {currentUser.finance.summ > currentUser.subscriptions.price 
          ? <p onClick={hanleClickSubs} className='my-info__item font24Norm'>Продлить подписку</p>
          : <p disabled={disabled} onClick={hanleClickPay} className='my-info__item font24Norm'>Пополнить счет</p>
        }
       
      </article>
      <article className='my-info__table'>
        <h2 className='my-info__name font24Norm'>Бонусы</h2>
        <p className='my-info__title font26Bold'> {currentUser.bonus.summ} баллов</p>
        <p onClick={hanleBonusText} className='my-info__item font24Norm '>Условия получения</p>
        {hover &&
        <InfoThemes isOpen={hover} name='bonus' onClose={hanleBonusText}/>
        }
      </article>

  

    </div>
)
   
}