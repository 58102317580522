//Группы продуктов в настройках

import React from "react";

const ExpectList = (props) => { 
  const item = props.item
  const exceptions =props.exceptions 
  const [choice, setChoice] = React.useState(false)
 // const [values, setValues] = React.useState(item.boolean)
  
  React.useEffect(() => {
    setChoice(exceptions.some(i=>  i._id === item._id))
  }, [exceptions]);

  function handleClick(item) { 
   props.onClick(item, choice)  
  } 
  
  return (
    <li 
      onClick={()=> handleClick(item)} 
      className={`button__type menuweek-button font20Norm ${choice && ('button__type_active color')}`}
    >
    {item.title}
    </li>
  )
}
  
export function ExpectItem(props) { 
  return (
    <ul className='form-settings__block_product '>
      {props.types.length > 0 
      ?  props.types.map((item)=> (
        <ExpectList item={item} key={item._id} onClick={props.handleExpectCheck} exceptions={props.exceptions} ingredientsData={props.ingredientsData}/>
 
        ))
      : ''
      }
    </ul>
  );
}