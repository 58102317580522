//Отображение списка стоп-продуктов в настройках

import React from 'react'; 
import { useSelector} from 'react-redux'

export function Ingredientpoint (props) {
  const id = props.item
  const {ingredients} = useSelector(state => state.ingredients)
  const ingredient = ingredients.find(i=> i._id === id) // находим по id нужный ингредиент*/

  function handleDelete() {
    props.onDelete(id)
  }
  return (
    <>
    {ingredient &&
    <li className='ingredient__stop'><p className='ingredient__name font20Norm'>{ingredient.nameRu}</p> 
      <p onClick={handleDelete} className='gg-cross m-size' />
    </li>
    }
    </>
  )
}