//Инструкция

import React from 'react';
import './Types.css';
import traditional from '../../../images/traditional-type.jpg';
import vegan from '../../../images/vegan.jpg';
import keto from '../../../images/keto.jpeg';
import table from '../../../images/table.jpg';
import whole from '../../../images/whole.jpg';
import child from '../../../images/child.jpg';

export function Types (Types) {
  
  return (
    <section className='types'>
      <ul className='types__list font20Bold'>
        <li className='types__item types__item_trad'>
            <div className='text__overlay text__overlay_trad'>
               <p className='types__title'>Традиционные блюда</p>
            </div>
            <img className='types__image' alt='Традиционные блюда' src={traditional} />
        </li> 
        <li className='types__item types__item_veg'>
            <div className='text__overlay text__overlay_veg'>
               <p className='types__title'>Веган</p>
            </div>
            <img className='types__image' alt='Веган блюда' src={vegan} />
        </li> 
        <li className='types__item types__item_keto'>
            <div className='text__overlay text__overlay_keto'>
               <p className='types__title'>Кето</p>
            </div>
            <img className='types__image' alt='Кето блюда' src={keto} />
        </li> 
        <li className='types__item types__item_table'>
            <div className='text__overlay text__overlay_table'>
               <p className='types__title'>Столы № 1-15</p>
            </div>
            <img className='types__image' alt='Столы №1,2,3,4,5,6,7,8,9,10' src={table} />
        </li> 
        <li className='types__item types__item_whole'>
            <div className='text__overlay text__overlay_whole'>
               <p className='types__title'>Цельное питание</p>
            </div>
            <img className='types__image' alt='Цельное питание' src={whole} />
        </li> 
        <li className='types__item types__item_child'>
            <div className='text__overlay text__overlay_child'>
               <p className='types__title'>Детское меню</p>
            </div>
            <img className='types__image' alt='Детское меню' src={child} />
        </li> 
      </ul>
     
    </section>
)
   
}