//Информация по бонусной системе

import React from 'react';
import './InfoThemes.css';

export function InfoThemes (props) {
  
  return (
    <div >
       <h3 className='info-themes__subtitle'>Условия</h3>
        <p className='info-themes__text'>
          За каждый выложенный рецепт начисляются 10 баллов, которые вы можете использовать для оплаты подписки
          1 месяц подписки = 200 баллам
        </p> 
        <ol className='info-themes__lists'>
          <li className='info-themes__item'>Рецепты выкладываются после модерации</li>
          <li className='info-themes__item'>Рецепт должен быть авторским</li>
          <li className='info-themes__item'>Фотографии должны быть авторские</li>
        </ol>
      </div>
)
   
}