import React from 'react';
import {TypesFood} from '../../atoms/index.js'
import {CheckboxDish} from '../index.js' 

export function ChoiceDish (props) {
  const meals = {...props.meals}
  const dish =meals.dish 

  function handleChange(item) {
    const updateDish =  dish.map((c) => c.nameEn === item.nameEn ? item : c)
    meals.dish = updateDish
    props.handleCheckBox(meals)
  }
  
  
  return (
    <div className='form-settings__degree'>
      <p className='form-settings__meals font24Bold'>{`Выберите блюда для ${props.meals.nameRu}а`}</p>
      {dish.length > 0 &&
        <TypesFood handleCheckBox={handleChange} types={dish}  />
      }
    </div>  
  )
}
