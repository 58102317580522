import './SceletonMenu.css';
import React from 'react';

export function SceletonRecipe(props) { 
  const [length, setLength] = React.useState(0)
 
  /*React.useEffect(()=> {
    if(window.innerWidth > 1024) { 
      setLength(3)
      } else if(window.innerWidth > 566 && window.innerWidth <= 1024) {
        setLength(2)
      } else if(window.innerWidth < 567) {
        setLength(1)
      } 
  }, [])*/
  return (
    <div className='sceleton-menu'>
    { [...Array(length)].map((item, index) =>  
      <div key={index} className='pattern pattern__recipe'>
        <div className='pattern__image pattern__image_recipe flare'> </div>
        <div className='pattern__info pattern__info_recipe'> 
          <div className='pattern__icon flare'></div>
          <div className='pattern__icon4 flare'></div>
          <div className='pattern__icon4 flare'></div>
          <div className='pattern__icon flare'></div>
        </div>
        </div>
        )}
  </div>
  )
}