import React, { useContext } from "react";
 
export default function Dot(props) { 
  const number = props.number;
  const slideNumber = props.slideNumber;

  return (
    <div
      className={`dot ${slideNumber === number ? "dot__selected" : ""}`}
      onClick={() => props.goToSlide(number)}
    />
  );
}