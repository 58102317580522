import React from 'react';
 
export function ModalContainer (props) {
 
  return (
    <section className={`modal modal_type_${props.title} ${props.isOpen ? ('modal_opened') : ''}`} >
      <div className={`modal__container modal__container__type_${props.title}`}>   
        <button onClick={props.onClose} type="button" className="modal__close" aria-label="Закрыть форму"></button>
        <h1 className='form__heading'>{props.head}</h1>
        {props.children}
      </div>
    </section>
  )
}
