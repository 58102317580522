import React from 'react';
import { Link } from 'react-router-dom'; 
import eyeOn from '../../../images/eye_open.png'
import eyeOff from '../../../images/eye_close.png'
import { useParams } from "react-router-dom";
import Form from '../../organisms/Form/Form.js'
import {useSelector} from 'react-redux' 
import {useFormValidation} from '../../../utils/Validator.js';
import {pattern, subscriptions} from '../../../constants/constants.js';

export function UpdatePassword (props) {
  const [type, setType] = React.useState(''); 
  const [icon, setIcon] = React.useState(eyeOff);
  const { values, handleChange, errors, isValid, emailError, nameError, resetForm,
    passwordError, errorsConfirmation,
  } = useFormValidation({ });
    const submitDisabled =  values.password === '' || !isValid || passwordError || errorsConfirmation || emailError;
    const { token, userId } = useParams();
  const handleToggle = () => {
    if (type==='password'){
       setIcon(eyeOn);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
 }

 function handleSubmit(e) {
  e.preventDefault();
  props.updatePassword(values.password, userId, token) 
  resetForm() 
}  
 console.log(values.password, values.passwordConfirmation)
 return (
  <main className='container'>
    <div className="form__container">
      <h2 className="form__title">Введите новый пароль</h2>
      <form className="form__info" onSubmit={handleSubmit} disabled={submitDisabled ? true : ''}>
        <fieldset className="form__data">
          <label className="input__label" htmlFor="password">
          <input 
            autoComplete="off" 
            type= {type}
            onChange={handleChange} 
            value={values.password || ''} 
            placeholder="Пароль" 
            className="form__item form__item_type_password" 
            name="password" 
            id="password" 
            required minLength="8" 
            maxLength="40"/>
          <span className="items-center" onClick={handleToggle}>
            <img className="eye-icon" src={icon} />
          </span>
          </label>
          
           <label className="input__label" htmlFor="password">
           <input 
           autoComplete="new-password" 
           type="password" 
           onChange={handleChange} 
           value={values.passwordConfirmation || ''} 
           placeholder="Подтверждение пароля" 
           className="form__item form__item_type_password" 
           name="passwordConfirmation" 
           id="passwordConfirmation" 
           required minLength="8" 
           maxLength="40"/>
           {errorsConfirmation && <span className="font16Norm form__item-error">{errorsConfirmation}</span>}
            </label> 
          {passwordError && <span className="font16Norm form__item-error">{passwordError}</span>}
        </fieldset>
        <button disabled={submitDisabled ? true : ''} className={`menuweek-button form__button color ${submitDisabled ? ('form__button_disabled') : 'section__link'}`} type="submit" aria-label='Сохранить'>Сохранить</button> 
      </form>
     
    </div> 
  </main>
  )
}
 
 