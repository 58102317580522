import React from 'react'
import './Loader.css'
 
export const Loader = (props) => {
  return (
    <div className="loader">
      <div className="doty dot1"></div>
      <div className="doty dot2"></div>
      <div className="doty dot3"></div>
    </div>
  )
};
