//Разметка выпадающего списка

import React from 'react';

export function SelectionCategory (props) {
  
  return (
    <option onChange={props.onChange}>{props.item.nameRu}</option>
  )
}

