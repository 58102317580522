import React from 'react';
 
export function PricePortion (props) {
  const ingredient=props.recipe // список ингредиентов в рецепте id, вес и мера
  const ingredients=props.ingredients //общий список
  
  let total = ingredient.reduce(function (acc, item) { 
    const ingBase = ingredients.find(i=> i._id ===item._id) // находим по id нужный ингредиент
   
    if(ingBase.price) {
      if(item.choisMeasure === ingBase.mainMeasure && ingBase.price.measureprice === ingBase.mainMeasure) {
        const price = (ingBase.price.rate/ingBase.price.weightprice)*item.weight
        return acc + Number(price)
      } else { 
        let rightWeight = 1
        const searchWeight = ingBase.measure.find(i=> i.nameRu === item.choisMeasure) // перевели вес в основной
          if(searchWeight !==undefined) {
            rightWeight = searchWeight.ratio*item.weight
          }
        //перевести цену заосновной вес
        const rightPrice = ingBase.measure.find(i=> i.nameRu === ingBase.price.measureprice).ratio
     //   console.log(item.choisMeasure, item)
        const price = (ingBase.price.rate/(ingBase.price.weightprice*rightPrice)) * rightWeight
        return acc + Number(price)
      }
     
    } else {
       return 0
    }
  },0);

  return (
    <>
    {total !== 0 &&
    <div className='portion__item font16Norm'>
      <span className="portion__name">Цена</span>
      <span className='portion__num'>{Math.round(total/props.portion)} &#8381;</span>
    </div> 
    }

    </>
  )
}
