import React from 'react'; 

export function Cooking(props) {
 
   return (
      <div className='product__cooking'>
      <label className='product-item__label'>
     
        <input 
          type="radio" 
          value='fresh'  
          onChange={props.onChange}
          className='product-item__choice' 
          name='thermoself' 
          id="fresh"
        />
        <span className="checkbox-green item__link"></span>
        <legend>свежий</legend>
      </label>
      <label className='product-item__label'> 
        <input 
          type="radio" 
          value='heat' 
          onChange={props.onChange}
          className='product-item__choice' 
          name='thermoself'
          id="heat"
        />  
       <span className="checkbox-green item__link"></span>
       <legend>варка</legend>
      </label>
      <label className='product-item__label'> 
        <input 
          type="radio" 
          value='frying'  
          onChange={props.onChange}
          name='thermoself'
          className='product-item__choice' 
          id="frying"
        />
        <span className="checkbox-green item__link"></span> 
       <legend>жарка</legend>

      </label>
   </div> 

)
   
}
