//  

import React from 'react';
import { Outlet } from 'react-router-dom'

export function Layout(props) {
  
  return (
    <main className='container'>
      <Outlet />
    </main>
  ) 
}
