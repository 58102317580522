// Разметка раскладки продуктов по блюдам

import React from 'react';
import {CurrentUserContext} from '../../../contexts/CurrentUserContext.js';

export function SubList(props) {
  const item=props.item
  const servings = props.servings
  
  return (
    <li className='product-dish'>
      <p className='product-dish__title'>{item.name}</p>
      <p className='product-dish__weight'>{Math.ceil((item.weight*servings))}</p>
      <p className='product-dish__measure'>{item.choisMeasure}</p>
    </li>
  )  
}
