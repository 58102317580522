import React from 'react'
import './Preloader.css'
 
export const Preloader = (props) => {
  return (
    <div className={`preloader preloader__open preloader_type_${props.type}`}>
      <div className={`preloader__container preloader__container_type__${props.type}`}>
        <span className="preloader__round"></span>
      </div>
    </div>
  )
};
